import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import userFeatureFlags from 'common/graphql/featureFlags';
import { IconName } from 'common/components/Icon';
import { useActionBar } from 'contractor/modules/Dashboard/modules/ActionBar/useActionBar';
import { ConnectAccountStatus } from 'types';

import MenuList from './MenuList';
import MenuItem from './MenuItem';

enum WorkflowAction {
  Financing = 'financing',
  Quotes = 'quotes',
  Invoices = 'invoices',
  Contracts = 'contracts',
}

type WorkflowDetail = {
  title: string;
  route: string;
  locked: boolean;
  actionable?: boolean;
  lockedCopy?: string;
  icon: IconName;
  featureStatus?: string;
}

const getHearthPayLockedCopy = (status: ConnectAccountStatus | null): string => {
  let hearthPayLockedCopy = '';
  switch (status) {
  case ConnectAccountStatus.REQUIRE_INFORMATION:
    hearthPayLockedCopy = 'More info needed';
    break;
  case ConnectAccountStatus.PENDING_VERIFICATION:
    hearthPayLockedCopy = 'Verification pending';
    break;
  default:
    hearthPayLockedCopy = 'Setup required';
  }
  return hearthPayLockedCopy;
};

type Props = {
  homeownerId?: string;
  onClose?: () => void;
}

const QuickActionsMenu = ({ homeownerId, onClose = () => {} }: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const {
    connectAccountStatus,
    organization,
    uiStates: {
      financingBlocked,
      paymentsButtonVisible,
      contractsButtonVisible,
    },
  } = useActionBar();
  const { quotes, hearthEssentials } = useReactiveVar(userFeatureFlags);

  const onClickQuickAction = useCallback((route: string) => {
    onClose();
    history.push(route, {
      background: /dashboard\/request/.test(route) ? location : null,
    });
  }, [history, location, onClose]);

  const financingDetails: WorkflowDetail = {
    title: 'Offer financing',
    route: `/dashboard/request/offer-financing/${homeownerId ?? ''}?source=quick_actions`,
    // TODO(skittles): use new icons once svg fixed
    icon: 'offer-financing',
    lockedCopy: 'Pending training',
    locked: financingBlocked,
    // this should not be clickable like the others
    actionable: !financingBlocked,
  };

  const contractsDetails: WorkflowDetail = {
    title: 'Send contract',
    route: organization?.contractServiceAccount?.termsAgreedAt ?
      `/dashboard/request/send-contract/${homeownerId ?? ''}?source=quick_actions` :
      '/dashboard/tools/contract-template?source=quick_actions',
    // TODO(skittles): use new icons once svg fixed
    icon: 'send-contract',
    lockedCopy: 'Setup required',
    locked: !organization?.contractServiceAccount?.termsAgreedAt,
  };

  const invoicesDetails: WorkflowDetail = {
    title: 'Request invoice',
    route: connectAccountStatus === ConnectAccountStatus.FULL_ACCESS ?
      `/dashboard/request/request-payment/${homeownerId ?? ''}?source=quick_actions` :
      '/dashboard/payments?source=quick_actions',
    // TODO(skittles): use new icons once svg fixed
    icon: 'request-payment',
    lockedCopy:
      getHearthPayLockedCopy(connectAccountStatus || null),
    locked: !connectAccountStatus || // locked if undefined or matches below
    [
      ConnectAccountStatus.NOT_SUBMITTED,
      ConnectAccountStatus.PENDING_VERIFICATION,
      ConnectAccountStatus.REQUIRE_INFORMATION,
    ].includes(connectAccountStatus),
  };

  const quotesDetails: WorkflowDetail = {
    title: 'Send quote',
    route: homeownerId ?
      `/dashboard/send_quote/?source=quick_actions&homeowner_id=${homeownerId}` :
      '/dashboard/send_quote/?source=quick_actions',
    icon: 'send-quote',
    locked: false,
    featureStatus: 'Beta',
  };

  const quickActionMapping: Record<WorkflowAction, WorkflowDetail> = {
    [WorkflowAction.Financing]: financingDetails,
    [WorkflowAction.Contracts]: contractsDetails,
    [WorkflowAction.Invoices]: invoicesDetails,
    [WorkflowAction.Quotes]: quotesDetails,
  };

  const quickActionItems = useMemo(() => (
    [
      WorkflowAction.Financing,
      quotes && !hearthEssentials && WorkflowAction.Quotes,
      contractsButtonVisible && WorkflowAction.Contracts,
      paymentsButtonVisible && WorkflowAction.Invoices,
    ].filter(Boolean) as WorkflowAction[]
  ), [quotes, hearthEssentials, contractsButtonVisible, paymentsButtonVisible]);

  return (
    <MenuList>
      {quickActionItems.map((item) => {
        const itemDetails = quickActionMapping[item];

        const menuItem = {
          title: itemDetails.title,
          icon: itemDetails.icon,
          locked: itemDetails.locked,
          actionable: itemDetails.actionable ?? true,
          statusIndicatorCopy: itemDetails.lockedCopy,
          action: () => onClickQuickAction(itemDetails.route),
          featureStatus: itemDetails.featureStatus,
        };

        return (
          <MenuItem key={menuItem.title} item={menuItem} featureStatus={menuItem.featureStatus} />
        );
      })}
    </MenuList>
  );
};

export default QuickActionsMenu;
