import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { FacebookProvider, Share } from 'react-facebook';
import {
  Box,
  Grid,
  Container,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMount } from 'react-use';
import { object, string } from 'yup';

import useLogEvent from 'common/hooks/useLogEvent';
import contractorUrl from 'contractor/utils/contractorDashboard';
import {
  contractorSelector,
  contractorFeatureFlagSelectors,
  adminSelector,
} from 'contractor/selectors/contractor';
import {
  organizationFeatureFlagSelectors,
  answeredProjectLeadsSelector,
  tierAbilityFlagsSelector,
} from 'contractor/selectors/organization';
import { Form } from 'common/components/formik';
import { useVerticalState, useUrl } from 'common/hooks';
import { Button, Note, Link } from 'common/components/material-ui';
import { CopyLink } from 'common/components/widgets';
import { BlockedModal, EmailLeadsModal, Header } from 'contractor/components/widgets';

import { WebsiteBannersNote, GetBannerModal, ProjectLeadsCard } from './components/marketing';

const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  contractorBadge: {
    height: '180px',
    width: '180px',
  },
  contractorFlyer: {
    height: 'auto',
    width: '100%',
    maxWidth: 162,
  },
  button: {
    marginTop: 16,
  },
  note: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  flyerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.basic300,
    borderRadius: 8,
    padding: 24,
  },
}));

type FacebookShareProps = {
  kitchenSinkBlocked: boolean;
  facebookUrl: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

// @TODO: refactor this entirely. This can be better converted to a HOC.
const FacebookShare = ({
  kitchenSinkBlocked,
  facebookUrl,
  onClick,
}: FacebookShareProps): JSX.Element => {
  if (kitchenSinkBlocked) {
    return (
      <Button
        variant="contained"
        size="medium"
        fullWidth
        buttonType="primary"
        onClick={onClick}
        style={{ marginTop: 'auto' }}
      >
        Post to Facebook
      </Button>
    );
  }

  return (
    <FacebookProvider appId={fbAppId}>
      <Share href={facebookUrl}>
        <Button
          variant="contained"
          size="medium"
          fullWidth
          buttonType="primary"
          style={{ marginTop: 'auto' }}
        >
          Post to Facebook
        </Button>
      </Share>
    </FacebookProvider>
  );
};

export type EmailLeadsFormValues = {
  file: string;
  emails: string;
};

const MarketingTools = (): JSX.Element => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const contractor = useSelector(contractorSelector);
  const kitchenSinkBlocked = useSelector(contractorFeatureFlagSelectors.kitchenSinkBlocked);
  const projectLeadsFlag = useSelector(organizationFeatureFlagSelectors.projectLeadsSep2019);
  const marketingBanners = useSelector(tierAbilityFlagsSelector).marketingBanners;
  const answeredProjectLeads = useSelector(answeredProjectLeadsSelector);
  const isAdmin = useSelector(adminSelector);
  const [blockedModalOpen, setBlockedModalOpen] = useState(false);
  const [emailLeadsModalOpen, setEmailLeadsModalOpen] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(-1);
  const [{ verticalMetadata }] = useVerticalState();
  const { appUrl } = useUrl();
  const applyUrl = useMemo(() => (
    contractor == null ? '' : appUrl(`/partners/${contractor.urlPath}/apply`)
  ), [contractor, appUrl]);

  const itly = useLogEvent();

  useMount(() => {
    itly.viewMarketingTools();
  });

  const handleExpand = (index: number): void => {
    if (index === expandedPanel) {
      setExpandedPanel(-1);
    } else {
      setExpandedPanel(index);
    }
  };

  const showProjectLeadsBanner = projectLeadsFlag && !answeredProjectLeads;
  const {
    showFlyer,
    showFacebookShare,
    websiteContent,
  } = verticalMetadata.dashboardMarketingTools;

  const facebookObj = contractorUrl(contractor, 'fb');
  const facebookUrl = facebookObj.baseDisplayUrl + facebookObj.path;

  const banner = {
    size: '180x180',
    description: 'This banner works best for impactful engagement',
    width: '180',
    height: '180',
  };

  const flyerButtonProps = kitchenSinkBlocked ?
    {
      onClick: () => setBlockedModalOpen(true),
    } : {
      onClick: () => window.open(appUrl(`/partners/${contractor.urlPath}/flyer`), '_blank'),
    };

  return (
    <Container maxWidth="lg">
      <Form<EmailLeadsFormValues>
        initialValues={{
          file: '',
          emails: '',
        }}
        validationSchema={
          object().shape({
            file: string(),
            emails: string(),
          })
        }
        // empty here due to how formik works, in addition to sending information
        // in EmailLeadsPreviewModal
        onSubmit={() => {}}
      >
        {/* TODO(EmailLeadsModal): resolve contractorId casting, due to selector */}
        {emailLeadsModalOpen &&
          <EmailLeadsModal
            isOpen
            onClose={() => setEmailLeadsModalOpen(false)}
            contractorId={contractor.id as unknown as string}
          />
        }
      </Form>
      <BlockedModal
        open={blockedModalOpen}
        setOpen={setBlockedModalOpen}
      />
      <GetBannerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        banner={banner}
        color="blue"
        itemType="badge"
        handleExpand={handleExpand}
      />
      <Box mt={1} mb={2}>
        <Header title="Offer financing" />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={showProjectLeadsBanner ? 6 : 12}>
          <Note
            title="Marketing tools"
            subtitle="Drive sales and increase your bottom line"
          >
            <Typography variant="body2">
              Find marketing and sales tools to help you engage
              with your customers and sell more efficiently.{' '}
              <Link
                component={RouterLink}
                to="/dashboard/learn/4ECqHToX0pOOx7lAuKe9KO"
              >
                Watch this video
              </Link>{' '}
              to learn the best practices for using these tools.
            </Typography>
          </Note>
        </Grid>
        {showProjectLeadsBanner &&
          <Grid item xs={6}>
            <ProjectLeadsCard />
          </Grid>
        }
        {isAdmin && marketingBanners &&
          <Grid item xs={12}>
            <WebsiteBannersNote
              content={websiteContent}
              handleExpand={handleExpand}
              expandedPanel={expandedPanel}
            />
          </Grid>
        }
      </Grid>
      <Box my={2}>
        <Grid container spacing={10}>
          <Grid item xs={12} className={classes.item}>
            <Note
              title="Email leads"
              subtitle={
                <Typography variant="h3">
                  Tell your clients and prospects that you offer financing
                </Typography>
              }
            >
              <Box display="flex" justifyContent="space-between">
                <Box width="50%">
                  <img
                    src="https://hearth.imgix.net/marketing-email-leads.png?auto=compress"
                    alt="printable flyers"
                    width="100%"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  pl={2}
                  width="50%"
                >
                  <Typography>
                    Notify your leads that your company now offers monthly payment options with
                    Hearth.
                  </Typography>
                  <Box maxWidth={200} my={2}>
                    <Button
                      variant="contained"
                      buttonType="primary"
                      onClick={() => setEmailLeadsModalOpen(true)}
                    >
                      Email leads
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Note>
          </Grid>
        </Grid>
      </Box>
      <Box my={2} />
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.item}>
          <Note
            title="Offer financing"
            subtitle={
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h3">
                  Copy Your Financing Link
                </Typography>
                <Box maxWidth={200}>
                  <CopyLink
                    onClick={() => itly.copyFinancingLink()}
                    text={applyUrl}
                  />
                </Box>
              </Box>
            }
            className={classes.note}
          />
        </Grid>
        {showFlyer &&
          <Grid item xs={12} className={classes.item}>
            <Note
              title="On site"
              className={classes.note}
            >
              <Box flex={1} display="flex" flexDirection="row">
                <Box className={classes.flyerContainer}>
                  <Typography variant="h3">
                    Personalized Flyer
                  </Typography>
                  <Box m={1} />
                  <Box display="flex" flexDirection="row" justifyContent="center">
                    <img
                      src="https://hearth.imgix.net/contractor-v2/gear_up_campaign/flyer-bg.png?auto=compress"
                      alt="printable flyers"
                      className={classes.contractorFlyer}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    buttonType="primary"
                    className={classes.button}
                    {...flyerButtonProps}
                    onClick={() => {
                      itly.downloadFlyer({ type: 'financing' });
                      flyerButtonProps.onClick();
                    }}
                  >
                    Download flyer
                  </Button>
                </Box>
                <Box m={1} />
                <Box className={classes.flyerContainer}>
                  <Typography variant="h3">
                    Informational Flyer
                  </Typography>
                  <Box m={1} />
                  <Box display="flex" flexDirection="row" justifyContent="center">
                    <img
                      src="https://hearth.imgix.net/contractormarketing/informational-flyer.png?auto=compress"
                      alt="informational flyers"
                      className={classes.contractorFlyer}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    buttonType="primary"
                    className={classes.button}
                    target="_blank"
                    href="https://hearth-documents.s3-us-west-2.amazonaws.com/Hearth+Informational+Flyer.pdf"
                    onClick={() => {
                      itly.downloadFlyer({ type: 'info' });
                    }}
                  >
                    Download flyer
                  </Button>
                </Box>
              </Box>
            </Note>
          </Grid>
        }
        {showFacebookShare &&
          <Grid item xs={12} className={classes.item}>
            <Note
              title="Social media"
              subtitle="Facebook"
              className={classes.note}
              description={
                `Post your financing link to your facebook page so your
                customers can request project financing.`
              }
            >
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Box maxWidth={200}>
                  <FacebookShare
                    kitchenSinkBlocked={kitchenSinkBlocked}
                    facebookUrl={facebookUrl}
                    onClick={() => {
                      itly.postFinancingLinkToFacebook();
                      setBlockedModalOpen(true);
                    }}
                  />
                </Box>
              </Box>
            </Note>
          </Grid>
        }
      </Grid>
    </Container>
  );
};

export default MarketingTools;
