import {
  createTheme,
  responsiveFontSizes,
  darken,
  lighten,
  ThemeOptions,
  Theme,
} from '@mui/material/styles';

export type PaletteColor = {
  color: string;
  contrast: string;
  secondaryColor: string;
};

type CustomTheme = {
  navbarHeight: number;
  drawerWidth: number;
  drawerZIndex: number;
  customPalette: Record<Palette, PaletteColor>;
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    h1a: React.CSSProperties;
    h2a: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    h1a?: React.CSSProperties;
    h2a?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p1: true;
    p2: true;
    p3: true;
    h1a: true;
    h2a: true;
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {
  }

  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends Partial<CustomTheme> {
  }
}

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface CommonColors extends Record<Color, string> {
  }
}

declare module '@mui/material/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {
  }

  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends Partial<CustomTheme> {
  }
}

// UI kitten color system
export const themeColors: Record<Color, string> = {
  basic100: '#FFFFFF',
  basic300: '#F4F6F9',
  basic500: '#E7E9EB',
  basic700: '#B6BCC2',
  basic900: '#86909A',
  basic1100: '#0D2135',
  primary100: '#E6EEF6',
  primary300: '#CCDDED',
  primary500: '#0154A4',
  primary700: '#134a8d',
  primary900: '#0D2135',
  success100: '#E7F5F1',
  success300: '#D0ECE2',
  success500: '#139F6F',
  success700: '#0E7753',
  success900: '#0A5038',
  info100: '#e8edf1',
  info300: '#8da3ba',
  info500: '#1b4774',
  info700: '#133251',
  info900: '#0e243a',
  warning100: '#FFF8EB',
  warning300: '#FFF1D6',
  warning500: '#FFB900',
  warning700: '#E5A701',
  warning900: '#926B01',
  danger100: '#FEE6DA',
  danger300: '#FCA28F',
  danger500: '#F84647',
  danger700: '#C9393A',
  danger900: '#7A2223',
  purple100: '#EFE9F2',
  purple300: '#B192BF',
  purple500: '#632580',
  purple700: '#451A5A',
  purple900: '#280F33',
  primaryTransparent100: 'rgba(1, 84, 164, 0.08)',
  primaryTransparent300: 'rgba(1, 84, 164, 0.24)',
  primaryTransparent500: 'rgba(1, 84, 164, 0.4)',
  successTransparent100: 'rgba(19, 159, 111, 0.08)',
  successTransparent300: 'rgba(19, 159, 111, 0.24)',
  successTransparent500: 'rgba(19, 159, 111, 0.4)',
  infoTransparent100: 'rgba(27, 71, 116, 0.08)',
  infoTransparent300: 'rgba(27, 71, 116, 0.24)',
  infoTransparent500: 'rgba(27, 71, 116, 0.4)',
  warningTransparent100: 'rgba(255, 185, 51, 0.08)',
  warningTransparent300: 'rgba(255, 185, 51, 0.24)',
  warningTransparent500: 'rgba(255, 185, 51, 0.4)',
  dangerTransparent100: 'rgba(248, 70, 71, 0.08)',
  dangerTransparent300: 'rgba(248, 70, 71, 0.24)',
  dangerTransparent500: 'rgba(248, 70, 71, 0.4)',
};

export const opaqueColor = (color: string, opacity: number): string => `${color}${opacity}`;

const pxToRem = createTheme().typography.pxToRem;

const deprecatedTheme: ThemeOptions = {
  drawerWidth: 204,
  drawerZIndex: 90,
  navbarHeight: 80,
  shape: {
    borderRadius: 1,
  },
  customPalette: {
    primary: {
      color: themeColors.primary500,
      contrast: themeColors.basic100,
      secondaryColor: darken(themeColors.primary500, 0.15),
    },
    secondary: {
      color: themeColors.basic1100,
      contrast: themeColors.basic100,
      secondaryColor: lighten(themeColors.basic1100, 0.15),
    },
    success: {
      color: themeColors.success500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.success700,
    },
    error: {
      color: themeColors.danger500,
      contrast: themeColors.basic100,
      secondaryColor: darken(themeColors.danger500, 0.15),
    },
    warning: {
      color: themeColors.warning500,
      contrast: themeColors.basic1100,
      secondaryColor: darken(themeColors.warning500, 0.15),
    },
    'common.basic': {
      color: themeColors.basic500,
      contrast: themeColors.basic1100,
      secondaryColor: themeColors.basic700,
    },
    'common.primary': {
      color: themeColors.primary500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.primary700,
    },
    'common.info': {
      color: themeColors.info500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.info700,
    },
    'common.warning': {
      color: themeColors.warning500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.warning700,
    },
    'common.success': {
      color: themeColors.success500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.success700,
    },
    'common.danger': {
      color: themeColors.danger500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.danger700,
    },
    'common.purple': {
      color: themeColors.purple500,
      contrast: themeColors.basic100,
      secondaryColor: themeColors.purple700,
    },
  },
  palette: {
    primary: {
      dark: themeColors.primary500,
      main: themeColors.basic1100,
    },
    secondary: {
      main: themeColors.basic100,
    },
    text: {
      primary: themeColors.basic1100,
      secondary: themeColors.basic700,
    },
    common: { ...themeColors },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif, 'Lora', serif",
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    button: {
      fontSize: pxToRem(16),
      fontWeight: 500,
      textTransform: 'none',
    },
    h1: {
      fontSize: pxToRem(42),
      fontWeight: 700,
    },
    h2: {
      fontSize: pxToRem(26),
      fontWeight: 700,
    },
    h3: {
      fontSize: pxToRem(20),
      fontWeight: 600,
    },
    h4: {
      fontSize: pxToRem(20),
      fontWeight: 400,
    },
    h5: {
      fontSize: pxToRem(18),
      fontWeight: 500,
    },
    h6: {
      fontSize: pxToRem(18),
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: pxToRem(16),
      fontWeight: 500,
    },
    body1: {
      fontSize: pxToRem(16),
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: pxToRem(14),
      fontWeight: 500,
    },
    body2: {
      fontSize: pxToRem(14),
      fontWeight: 400,
    },
    caption: {
      fontSize: pxToRem(12),
      fontWeight: 400,
    },
    overline: {
      fontSize: pxToRem(11),
      fontWeight: 400,
    },
    // TODO(design_system): eventually remove all typography above these
    h1a: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(36),
    },
    h2a: {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(28),
    },
    p1: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    p2: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
    },
    p3: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        input: {
          fontSize: 16,
        },
        inputAdornedStart: {
          paddingLeft: 8,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          fontSize: 16,
        },
        outlined: {
          fontSize: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: 1.3,
          padding: '12px 20px',
          fontWeight: 500,
          textAlign: 'center',
          borderRadius: 4,
        },
        outlined: {
          // Unset default padding set by MUI
          padding: undefined,
        },
        sizeSmall: {
          fontSize: 14,
          lineHeight: 1.3,
          padding: '6px 20px',
          fontWeight: 600,
        },
        sizeLarge: {
          fontSize: 16,
          lineHeight: 1.3,
          padding: '16px 24px',
          fontWeight: 600,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        contained: {
          marginLeft: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          paddingLeft: '25px',
          paddingRight: '25px',
        },
        action: {
          marginRight: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        wrapped: {
          fontSize: 14,
          textTransform: 'uppercase',
        },
        root: {
          fontSize: 14,
          selected: {
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 14,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(18),
        },
        fontSizeSmall: {
          fontSize: pxToRem(12),
        },
        fontSizeLarge: {
          fontSize: pxToRem(27),
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(18),
        },
        fontSizeSmall: {
          fontSize: pxToRem(12),
        },
        fontSizeLarge: {
          fontSize: pxToRem(27),
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: themeColors.primary100,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${themeColors.basic500}`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 40px',
          height: '100%',
        },
      },
    },
  },
};

const theme = createTheme(deprecatedTheme);

export default responsiveFontSizes(theme);
