/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: amplitude-js
 * Tracking Plan Version: 70
 * Build: 1.0.0
 *
 * [View Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/hearth/Hearth/implementation/web)
 */

import amplitude, { AmplitudeClient, Callback, Config } from 'amplitude-js';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: 'b84062303289607c27fc463700e786b9',
  production: '737804e1c495059da42903b4044bde6f'
};

/**
 * Default Amplitude Config. Contains tracking plan information.
 */
export const DefaultConfig: ConfigExt = {
  plan: {
    version: '70',
    branch: 'main',
    source: 'web'
  }
};

export interface LoadOptions {
  environment?: Environment;
  disabled?: boolean;
  client?: {
    apiKey?: string;
    config?: Partial<ConfigExt>;
    instance?: AmplitudeClient;
  }
}

export interface IdentifyProperties {
  contractor_id?: string;
  email?: string;
  feature_flags?: string[];
  first_app_login_at?: string;
  first_name?: string;
  homeowner_id?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  last_name?: string;
  organization_id?: string;
  referrer?: string;
  referring_domain?: string;
  role?: string;
  user_type?: "contractor" | "homeowner" | "contract_service_signer";
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
}

export interface AddAdditionalInvoiceRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_request: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, contracts, quotes |
   */
  source: "financing" | "invoice" | "contracts" | "quotes";
}

export interface AddContractPaymentDetailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_payments: number;
}

export interface AddExistingClientProperties {
  search_used: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, quotes, contracts |
   */
  source: "financing" | "invoice" | "quotes" | "contracts";
}

export interface AddPaymentInContractTemplateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_payments: number;
}

export interface AnswerQuickPollProperties {
  answer: string;
  questionnaire_question_id: string;
}

export interface ApproveQuoteProperties {
  quote_id: string;
}

export interface CancelContractProperties {
  contract_service_contract_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | client, contract |
   */
  source: "client" | "contract";
}

export interface CancelQuoteProperties {
  quote_id: string;
}

export interface ChangeSettingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  fields: string[];
}

export interface ClaimRewardProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reward_in_dollar: number;
}

export interface CompleteKycSectionProperties {
  flow: string;
  section: string;
}

export interface CompleteLearnCenterArticleProperties {
  article_id: string;
  article_name: string;
}

export interface CompleteOnboardingSectionProperties {
  section: string;
}

export interface ConfirmKycPersonalInfoProperties {
  flow: string;
}

export interface ConnectBankInfoProperties {
  source: string;
}

export interface ConvertQuoteToContractProperties {
  quote_id: string;
}

export interface DismissSuggestedActionProperties {
  suggested_action_cta_copy: string;
  suggested_action_id: string;
  suggested_action_type_id: string;
}

export interface DownloadContractProperties {
  contract_service_contract_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | contract, client |
   */
  source: "contract" | "client";
}

export interface DownloadFlyerProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, info |
   */
  type: "financing" | "info";
}

export interface EditKycPersonalInfoProperties {
  flow: string;
}

export interface HoverOverInsightCardProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing_trends, financing_funnel, financed_amounts, customer_profile, steve_weyl, payments_trends, overdue_unpaid_invoices, invoices_and_value, avg_days_to_paid_invoice, invoices_breakdown, contracts_trends, signed_contracts, unsigned_contracts, contracts_and_value |
   */
  insight_card_name:
    | "financing_trends"
    | "financing_funnel"
    | "financed_amounts"
    | "customer_profile"
    | "steve_weyl"
    | "payments_trends"
    | "overdue_unpaid_invoices"
    | "invoices_and_value"
    | "avg_days_to_paid_invoice"
    | "invoices_breakdown"
    | "contracts_trends"
    | "signed_contracts"
    | "unsigned_contracts"
    | "contracts_and_value";
}

export interface PayInvoiceProperties {
  connect_invoice_id: string;
  homeowner_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ach_debit, credit, debit, unknown |
   */
  method: "ach_debit" | "credit" | "debit" | "unknown";
}

export interface PreviewQuoteInRevisionFormProperties {
  quote_id?: string;
}

export interface RemoveContractPaymentDetailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_payments: number;
}

export interface RemoveInvoiceRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_request: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, contracts, quotes |
   */
  source: "financing" | "invoice" | "contracts" | "quotes";
}

export interface RemovePaymentInContractTemplateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_payments: number;
}

export interface RequestChangesQuoteProperties {
  quote_id: string;
}

export interface ResendContractProperties {
  contract_service_contract_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | client, contract |
   */
  source: "client" | "contract";
}

export interface ResendQuoteProperties {
  quote_id: string;
}

export interface SaveContractTemplateProperties {
  name_of_template: string;
}

export interface SearchPlaidInstitutionProperties {
  flow: string;
  keyword: string;
}

export interface SendBannerInstructionsProperties {
  type: string;
}

export interface SendContractsRequestFormProperties {
  /**
   * The button\_position property for the send contracts request form event indicates which button the contractor clicked on to sign and send the contracts request form
   */
  button_position: string;
  contract_name: string;
  contract_service_contract_id: string;
  financing_link_included: boolean;
  homeowner_id: string;
  schedule_invoice: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | text, email, text_and_email |
   */
  send_as: "text" | "email" | "text_and_email";
  /**
   * The source property for the send contracts request form event indicates which button the contractor clicked on to sign and send the contracts request form
   */
  source?: string;
  template_name: string;
}

export interface SendEmailLeadsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_emails_uploaded: number;
}

export interface SendFinancingRequestFormProperties {
  homeowner_id: string;
  schedule_invoice: boolean;
}

export interface SendInvoiceRequestFormProperties {
  connect_invoice_id: string;
  homeowner_id: string;
  homeowner_pays_fees: boolean;
  scheduled: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, contracts, quotes |
   */
  source: "financing" | "invoice" | "contracts" | "quotes";
}

export interface SendQuoteRequestFormProperties {
  financing_link_included: boolean;
  homeowner_id: string;
  quote_id: string;
  quote_name: string;
}

export interface SendRevisedQuoteProperties {
  financing_link_included: boolean;
  homeowner_id: string;
  prior_quote_id: string;
  quote_id: string;
  quote_name: string;
}

export interface ShareCustomerFeedbackProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hearth_pay, financing |
   */
  type: "hearth_pay" | "financing";
}

export interface SignContractProperties {
  contract_service_contract_id: string;
}

export interface SubmitEmailLeadsListProperties {
  type: string;
}

export interface SubmitFeedbackFormProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, hearth_pay |
   */
  type: "financing" | "hearth_pay";
}

export interface SubmitFeedbackOnLearnCenterArticleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes, no |
   */
  answer: "yes" | "no";
  article_id: string;
  article_name: string;
}

export interface SubmitFinancingFormProperties {
  employment_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fico: number;
  has_coapplicant: boolean;
  homeowner_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  income_in_dollar: number;
  loan_inquiry_uuid: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  request_amount_in_dollar: number;
  state: string;
}

export interface SubmitInsightFeedbackModuleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, payments, contracts |
   */
  insight_category: "financing" | "payments" | "contracts";
}

export interface SubmitKycProperties {
  is_reapplication: boolean;
}

export interface SubmitPayoutProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  amount_in_cents: number;
  stripe_payout_id: string;
}

export interface SubmitQuotesFeedbackProperties {
  feedback: string;
}

export interface SubmitRequestForChangesQuoteProperties {
  quote_id: string;
}

export interface ToggleInsightCardTimePeriodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing_trends, financing_funnel, financed_amounts, customer_profile, steve_weyl, payments_trends, overdue_unpaid_invoices, invoices_and_value, avg_days_to_paid_invoice, invoices_breakdown, contracts_trends, signed_contracts, unsigned_contracts, contracts_and_value |
   */
  insight_card_name:
    | "financing_trends"
    | "financing_funnel"
    | "financed_amounts"
    | "customer_profile"
    | "steve_weyl"
    | "payments_trends"
    | "overdue_unpaid_invoices"
    | "invoices_and_value"
    | "avg_days_to_paid_invoice"
    | "invoices_breakdown"
    | "contracts_trends"
    | "signed_contracts"
    | "unsigned_contracts"
    | "contracts_and_value";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, ytd, alltime |
   */
  time_period: "month" | "quarter" | "ytd" | "alltime";
}

export interface ToggleInsightCardWorkflowStatusProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing_trends, financing_funnel, financed_amounts, customer_profile, steve_weyl, payments_trends, overdue_unpaid_invoices, invoices_and_value, avg_days_to_paid_invoice, invoices_breakdown, contracts_trends, signed_contracts, unsigned_contracts, contracts_and_value |
   */
  insight_card_name:
    | "financing_trends"
    | "financing_funnel"
    | "financed_amounts"
    | "customer_profile"
    | "steve_weyl"
    | "payments_trends"
    | "overdue_unpaid_invoices"
    | "invoices_and_value"
    | "avg_days_to_paid_invoice"
    | "invoices_breakdown"
    | "contracts_trends"
    | "signed_contracts"
    | "unsigned_contracts"
    | "contracts_and_value";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | funded, requested, prequalified, paid, unpaid, overdue, signed, unsigned |
   */
  status: "funded" | "requested" | "prequalified" | "paid" | "unpaid" | "overdue" | "signed" | "unsigned";
}

export interface ToggleInsightCardWorkflowUnitProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing_trends, financing_funnel, financed_amounts, customer_profile, steve_weyl, payments_trends, overdue_unpaid_invoices, invoices_and_value, avg_days_to_paid_invoice, invoices_breakdown, contracts_trends, signed_contracts, unsigned_contracts, contracts_and_value |
   */
  insight_card_name:
    | "financing_trends"
    | "financing_funnel"
    | "financed_amounts"
    | "customer_profile"
    | "steve_weyl"
    | "payments_trends"
    | "overdue_unpaid_invoices"
    | "invoices_and_value"
    | "avg_days_to_paid_invoice"
    | "invoices_breakdown"
    | "contracts_trends"
    | "signed_contracts"
    | "unsigned_contracts"
    | "contracts_and_value";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | dollar, count |
   */
  unit: "dollar" | "count";
}

export interface ViewAllClientListProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all, financing, hearth_pay, contracts, quotes |
   */
  section: "all" | "financing" | "hearth_pay" | "contracts" | "quotes";
  /**
   * are they viewing just their clients or the whole orgs
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all_clients, my_clients |
   */
  view_mode: "all_clients" | "my_clients";
}

export interface ViewBannerInstallationWebsiteLinkProperties {
  link_name: string;
}

export interface ViewClientContractDetailProperties {
  contract_service_contract_id: string;
  homeowner_id: string;
}

export interface ViewClientDetailProperties {
  homeowner_id: string;
}

export interface ViewClientFinancingDetailProperties {
  homeowner_id: string;
}

export interface ViewClientInvoiceDetailProperties {
  connect_invoice_id: string;
  homeowner_id: string;
}

export interface ViewClientQuoteDetailProperties {
  financing_link_included: boolean;
  homeowner_id: string;
  prior_quote_id?: string;
  quote_id: string;
  quote_name: string;
}

export interface ViewConnectBankInfoStepProperties {
  source: string;
}

export interface ViewContractProperties {
  contract_service_contract_id: string;
}

export interface ViewContractLandingPageProperties {
  source?: string;
}

export interface ViewContractListProperties {
  source?: string;
}

export interface ViewContractsRequestFormProperties {
  source?: string;
}

export interface ViewContractTemplatesProperties {
  template_name: string;
}

export interface ViewCustomerFeedbackProperties {
  /**
   * financing-feedback, hearth-pay-feedback
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, hearth_pay |
   */
  type: "financing" | "hearth_pay";
}

export interface ViewExistingClientProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, contracts, quotes |
   */
  source: "financing" | "invoice" | "contracts" | "quotes";
}

export interface ViewFinancingFormProperties {
  homeowner_id?: string;
  loan_inquiry_uuid: string;
  source?: string;
}

export interface ViewFinancingListProperties {
  source?: string;
}

export interface ViewFinancingOffersProperties {
  homeowner_id: string;
  loan_inquiry_uuid: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  max_loan_amount_in_dollar: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 1 |
   */
  min_apr_in_decimal: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_lenders: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_offers: number;
}

export interface ViewFinancingRequestFormProperties {
  source?: string;
}

export interface ViewInsightsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, payments, contracts |
   */
  insight_category: "financing" | "payments" | "contracts";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | dashboard, insights_modal |
   */
  source?: "dashboard" | "insights_modal";
}

export interface ViewInvoiceProperties {
  connect_invoice_id: string;
  homeowner_id: string;
}

export interface ViewInvoiceListProperties {
  source?: string;
}

export interface ViewInvoiceRequestFormProperties {
  source?: string;
}

export interface ViewInvoiceRequestSettingsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing, invoice, contracts, quotes |
   */
  source: "financing" | "invoice" | "contracts" | "quotes";
}

export interface ViewKycFlowProperties {
  flow: string;
  source?: string;
}

export interface ViewLearnCenterArticleProperties {
  article_id: string;
  article_name: string;
}

export interface ViewPaymentLandingPageProperties {
  source?: string;
}

export interface ViewQuoteProperties {
  financing_link_included: boolean;
  quote_id: string;
  quote_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | created, viewed, accepted, changes_requested, canceled, expired, overwritten |
   */
  quote_status: "created" | "viewed" | "accepted" | "changes_requested" | "canceled" | "expired" | "overwritten";
  revised: boolean;
}

export interface ViewQuoteRequestFormProperties {
  source?: string;
}

export interface ViewQuoteRevisionFormProperties {
  homeowner_id: string;
  quote_id: string;
  /**
   * A homeowner has or has not requested changes to the quote that the contractor is revising
   */
  requested_changes: boolean;
}

export interface ViewQuotesListProperties {
  source?: string;
}

export interface ViewSettingSectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | api_token, billing, change_password, demo_mode, financing, flyer, notification, project_intent_contract, hearth_pay |
   */
  section:
    | "api_token"
    | "billing"
    | "change_password"
    | "demo_mode"
    | "financing"
    | "flyer"
    | "notification"
    | "project_intent_contract"
    | "hearth_pay";
}

export interface ViewSuggestedActionProperties {
  suggested_action_cta_copy: string;
  suggested_action_id: string;
  suggested_action_type_id: string;
}

export interface ViewWistiaChannelProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | dashboard, learn_center |
   */
  source: "dashboard" | "learn_center";
}

export interface WatchSteveWeylVideoProperties {
  source: string;
}

export interface InsightCardNameProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | financing_trends, financing_funnel, financed_amounts, customer_profile, steve_weyl, payments_trends, overdue_unpaid_invoices, invoices_and_value, avg_days_to_paid_invoice, invoices_breakdown, contracts_trends, signed_contracts, unsigned_contracts, contracts_and_value |
   */
  insight_card_name:
    | "financing_trends"
    | "financing_funnel"
    | "financed_amounts"
    | "customer_profile"
    | "steve_weyl"
    | "payments_trends"
    | "overdue_unpaid_invoices"
    | "invoices_and_value"
    | "avg_days_to_paid_invoice"
    | "invoices_breakdown"
    | "contracts_trends"
    | "signed_contracts"
    | "unsigned_contracts"
    | "contracts_and_value";
}

export class Context implements BaseEvent {
  event_type = 'Context';
  constructor() {}
}

export class Identify implements BaseEvent {
  event_type = 'Identify';
  event_properties: IdentifyProperties;

  constructor(event_properties: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class Group implements BaseEvent {
  event_type = 'Group';
  constructor() {}
}

export class AddAdditionalInvoiceRequest implements BaseEvent {
  event_type = 'add additional invoice request';

  constructor(
    public event_properties: AddAdditionalInvoiceRequestProperties,
  ) {}
}

export class AddAdditionalProvisionsToContractRequestForm implements BaseEvent {
  event_type = 'add additional provisions to contract request form';
}

export class AddAttachmentsToContractRequestForm implements BaseEvent {
  event_type = 'add attachments to contract request form';
}

export class AddChecklistInContractTemplate implements BaseEvent {
  event_type = 'add checklist in contract template';
}

export class AddContractPaymentDetail implements BaseEvent {
  event_type = 'add contract payment detail';

  constructor(
    public event_properties: AddContractPaymentDetailProperties,
  ) {}
}

export class AddExistingClient implements BaseEvent {
  event_type = 'add existing client';

  constructor(
    public event_properties: AddExistingClientProperties,
  ) {}
}

export class AddPaymentInContractTemplate implements BaseEvent {
  event_type = 'add payment in contract template';

  constructor(
    public event_properties: AddPaymentInContractTemplateProperties,
  ) {}
}

export class AddTextEntryListInContractTemplate implements BaseEvent {
  event_type = 'add text entry list in contract template';
}

export class AnswerQuickPoll implements BaseEvent {
  event_type = 'answer quick poll';

  constructor(
    public event_properties: AnswerQuickPollProperties,
  ) {}
}

export class ApproveQuote implements BaseEvent {
  event_type = 'approve quote';

  constructor(
    public event_properties: ApproveQuoteProperties,
  ) {}
}

export class CancelContract implements BaseEvent {
  event_type = 'cancel contract';

  constructor(
    public event_properties: CancelContractProperties,
  ) {}
}

export class CancelEditingContractTemplate implements BaseEvent {
  event_type = 'cancel editing contract template';
}

export class CancelQuote implements BaseEvent {
  event_type = 'cancel quote';

  constructor(
    public event_properties: CancelQuoteProperties,
  ) {}
}

export class ChangeSetting implements BaseEvent {
  event_type = 'change setting';

  constructor(
    public event_properties: ChangeSettingProperties,
  ) {}
}

export class ClaimReward implements BaseEvent {
  event_type = 'claim reward';

  constructor(
    public event_properties: ClaimRewardProperties,
  ) {}
}

export class CompleteKycSection implements BaseEvent {
  event_type = 'complete kyc section';

  constructor(
    public event_properties: CompleteKycSectionProperties,
  ) {}
}

export class CompleteLearnCenterArticle implements BaseEvent {
  event_type = 'complete learn center article';

  constructor(
    public event_properties: CompleteLearnCenterArticleProperties,
  ) {}
}

export class CompleteOnboardingSection implements BaseEvent {
  event_type = 'complete onboarding section';

  constructor(
    public event_properties: CompleteOnboardingSectionProperties,
  ) {}
}

export class CompletePayoutDetails implements BaseEvent {
  event_type = 'complete payout details';
}

export class ConfirmKycPersonalInfo implements BaseEvent {
  event_type = 'confirm kyc personal info';

  constructor(
    public event_properties: ConfirmKycPersonalInfoProperties,
  ) {}
}

export class ConnectBankInfo implements BaseEvent {
  event_type = 'connect bank info';

  constructor(
    public event_properties: ConnectBankInfoProperties,
  ) {}
}

export class ConvertQuoteToContract implements BaseEvent {
  event_type = 'convert quote to contract';

  constructor(
    public event_properties: ConvertQuoteToContractProperties,
  ) {}
}

export class CopyFinancingLink implements BaseEvent {
  event_type = 'copy financing link';
}

export class CreateNewContractTemplate implements BaseEvent {
  event_type = 'create new contract template';
}

export class DeleteContractTemplate implements BaseEvent {
  event_type = 'delete contract template';
}

export class DismissCheckingAccountPaintedDoorModal implements BaseEvent {
  event_type = 'dismiss checking account painted door modal';
}

export class DismissCheckingAccountPaintedDoorSuggestedAction implements BaseEvent {
  event_type = 'dismiss checking account painted door suggested action';
}

export class DismissFirstContractTemplateBanner implements BaseEvent {
  event_type = 'dismiss first contract template banner';
}

export class DismissInsightsFirstTimeModal implements BaseEvent {
  event_type = 'dismiss insights first time modal';
}

export class DismissSuggestedAction implements BaseEvent {
  event_type = 'dismiss suggested action';

  constructor(
    public event_properties: DismissSuggestedActionProperties,
  ) {}
}

export class DownloadContract implements BaseEvent {
  event_type = 'download contract';

  constructor(
    public event_properties: DownloadContractProperties,
  ) {}
}

export class DownloadContractTemplate implements BaseEvent {
  event_type = 'download contract template';
}

export class DownloadFlyer implements BaseEvent {
  event_type = 'download flyer';

  constructor(
    public event_properties: DownloadFlyerProperties,
  ) {}
}

export class EditContractTemplate implements BaseEvent {
  event_type = 'edit contract template';
}

export class EditContractTemplateName implements BaseEvent {
  event_type = 'edit contract template name';
}

export class EditContractTerms implements BaseEvent {
  event_type = 'edit contract terms';
}

export class EditDescriptionInContractTemplate implements BaseEvent {
  event_type = 'edit description in contract template';
}

export class EditDescriptionOfServicesInContractRequestForm implements BaseEvent {
  event_type = 'edit description of services in contract request form';
}

export class EditKycPersonalInfo implements BaseEvent {
  event_type = 'edit kyc personal info';

  constructor(
    public event_properties: EditKycPersonalInfoProperties,
  ) {}
}

export class FinishContractsSetup implements BaseEvent {
  event_type = 'finish contracts setup';
}

export class HoverOverInsightCard implements BaseEvent {
  event_type = 'hover over insight card';

  constructor(
    public event_properties: HoverOverInsightCardProperties,
  ) {}
}

export class InsightsCallAccountManager implements BaseEvent {
  event_type = 'insights call account manager';
}

export class InviteTeamMember implements BaseEvent {
  event_type = 'invite team member';
}

export class JoinCheckingAccountWaitlist implements BaseEvent {
  event_type = 'join checking account waitlist';
}

export class LogIn implements BaseEvent {
  event_type = 'log in';
}

export class NoInterestInCheckingAccount implements BaseEvent {
  event_type = 'no interest in checking account';
}

export class OpenBannerDesign implements BaseEvent {
  event_type = 'open banner design';
}

export class OpenBannerInstallationHelp implements BaseEvent {
  event_type = 'open banner installation help';
}

export class OpenContentForYourFinancingPage implements BaseEvent {
  event_type = 'open content for your financing page';
}

export class OpenWebsitePaymentCalculator implements BaseEvent {
  event_type = 'open website payment calculator';
}

export class PayInvoice implements BaseEvent {
  event_type = 'pay invoice';

  constructor(
    public event_properties: PayInvoiceProperties,
  ) {}
}

export class PostFinancingLinkToFacebook implements BaseEvent {
  event_type = 'post financing link to facebook';
}

export class PreviewContract implements BaseEvent {
  event_type = 'preview contract';
}

export class PreviewQuoteInRequestForm implements BaseEvent {
  event_type = 'preview quote in request form';
}

export class PreviewQuoteInRevisionForm implements BaseEvent {
  event_type = 'preview quote in revision form';

  constructor(
    public event_properties?: PreviewQuoteInRevisionFormProperties,
  ) {}
}

export class PreviewSampleQuote implements BaseEvent {
  event_type = 'preview sample quote';
}

export class RemoveContractPaymentDetail implements BaseEvent {
  event_type = 'remove contract payment detail';

  constructor(
    public event_properties: RemoveContractPaymentDetailProperties,
  ) {}
}

export class RemoveInvoiceRequest implements BaseEvent {
  event_type = 'remove invoice request';

  constructor(
    public event_properties: RemoveInvoiceRequestProperties,
  ) {}
}

export class RemovePaymentInContractTemplate implements BaseEvent {
  event_type = 'remove payment in contract template';

  constructor(
    public event_properties: RemovePaymentInContractTemplateProperties,
  ) {}
}

export class RequestChangesQuote implements BaseEvent {
  event_type = 'request changes quote';

  constructor(
    public event_properties: RequestChangesQuoteProperties,
  ) {}
}

export class ResendContract implements BaseEvent {
  event_type = 'resend contract';

  constructor(
    public event_properties: ResendContractProperties,
  ) {}
}

export class ResendQuote implements BaseEvent {
  event_type = 'resend quote';

  constructor(
    public event_properties: ResendQuoteProperties,
  ) {}
}

export class SaveContractTemplate implements BaseEvent {
  event_type = 'save contract template';

  constructor(
    public event_properties: SaveContractTemplateProperties,
  ) {}
}

export class SearchPlaidInstitution implements BaseEvent {
  event_type = 'search plaid institution';

  constructor(
    public event_properties: SearchPlaidInstitutionProperties,
  ) {}
}

export class SendBannerInstructions implements BaseEvent {
  event_type = 'send banner instructions';

  constructor(
    public event_properties: SendBannerInstructionsProperties,
  ) {}
}

export class SendContractsRequestForm implements BaseEvent {
  event_type = 'send contracts request form';

  constructor(
    public event_properties: SendContractsRequestFormProperties,
  ) {}
}

export class SendEmailLeads implements BaseEvent {
  event_type = 'send email leads';

  constructor(
    public event_properties: SendEmailLeadsProperties,
  ) {}
}

export class SendFinancingRequestForm implements BaseEvent {
  event_type = 'send financing request form';

  constructor(
    public event_properties: SendFinancingRequestFormProperties,
  ) {}
}

export class SendInvoiceRequestForm implements BaseEvent {
  event_type = 'send invoice request form';

  constructor(
    public event_properties: SendInvoiceRequestFormProperties,
  ) {}
}

export class SendQuoteRequestForm implements BaseEvent {
  event_type = 'send quote request form';

  constructor(
    public event_properties: SendQuoteRequestFormProperties,
  ) {}
}

export class SendRevisedQuote implements BaseEvent {
  event_type = 'send revised quote';

  constructor(
    public event_properties: SendRevisedQuoteProperties,
  ) {}
}

export class SetPassword implements BaseEvent {
  event_type = 'set password';
}

export class ShareCustomerFeedback implements BaseEvent {
  event_type = 'share customer feedback';

  constructor(
    public event_properties: ShareCustomerFeedbackProperties,
  ) {}
}

export class SignContract implements BaseEvent {
  event_type = 'sign contract';

  constructor(
    public event_properties: SignContractProperties,
  ) {}
}

export class SignTermsAndConditions implements BaseEvent {
  event_type = 'sign terms and conditions';
}

export class SignTermsAndConditionsForEmailLeads implements BaseEvent {
  event_type = 'sign terms and conditions for email leads';
}

export class SkipWelcomeTour implements BaseEvent {
  event_type = 'skip welcome tour';
}

export class StartPlaidContractorAccountLink implements BaseEvent {
  event_type = 'start plaid contractor account link';
}

export class SubmitEmailLeadsList implements BaseEvent {
  event_type = 'submit email leads list';

  constructor(
    public event_properties: SubmitEmailLeadsListProperties,
  ) {}
}

export class SubmitFeedbackForm implements BaseEvent {
  event_type = 'submit feedback form';

  constructor(
    public event_properties: SubmitFeedbackFormProperties,
  ) {}
}

export class SubmitFeedbackOnLearnCenterArticle implements BaseEvent {
  event_type = 'submit feedback on learn center article';

  constructor(
    public event_properties: SubmitFeedbackOnLearnCenterArticleProperties,
  ) {}
}

export class SubmitFinancingForm implements BaseEvent {
  event_type = 'submit financing form';

  constructor(
    public event_properties: SubmitFinancingFormProperties,
  ) {}
}

export class SubmitInsightFeedbackModule implements BaseEvent {
  event_type = 'submit insight feedback module';

  constructor(
    public event_properties: SubmitInsightFeedbackModuleProperties,
  ) {}
}

export class SubmitKyc implements BaseEvent {
  event_type = 'submit kyc';

  constructor(
    public event_properties: SubmitKycProperties,
  ) {}
}

export class SubmitOnboarding implements BaseEvent {
  event_type = 'submit onboarding';
}

export class SubmitPayout implements BaseEvent {
  event_type = 'submit payout';

  constructor(
    public event_properties: SubmitPayoutProperties,
  ) {}
}

export class SubmitQuotesFeedback implements BaseEvent {
  event_type = 'submit quotes feedback';

  constructor(
    public event_properties: SubmitQuotesFeedbackProperties,
  ) {}
}

export class SubmitRequestForChangesQuote implements BaseEvent {
  event_type = 'submit request for changes quote';

  constructor(
    public event_properties: SubmitRequestForChangesQuoteProperties,
  ) {}
}

export class TakeWelcomeTour implements BaseEvent {
  event_type = 'take welcome tour';
}

export class ToggleInsightCardTimePeriod implements BaseEvent {
  event_type = 'toggle insight card time period';

  constructor(
    public event_properties: ToggleInsightCardTimePeriodProperties,
  ) {}
}

export class ToggleInsightCardWorkflowStatus implements BaseEvent {
  event_type = 'toggle insight card workflow status';

  constructor(
    public event_properties: ToggleInsightCardWorkflowStatusProperties,
  ) {}
}

export class ToggleInsightCardWorkflowUnit implements BaseEvent {
  event_type = 'toggle insight card workflow unit';

  constructor(
    public event_properties: ToggleInsightCardWorkflowUnitProperties,
  ) {}
}

export class ViewAllClientList implements BaseEvent {
  event_type = 'view all client list';

  constructor(
    public event_properties: ViewAllClientListProperties,
  ) {}
}

export class ViewBannerInstallationWebsiteLink implements BaseEvent {
  event_type = 'view banner installation website link';

  constructor(
    public event_properties: ViewBannerInstallationWebsiteLinkProperties,
  ) {}
}

export class ViewCheckingAccountPaintedDoorModal implements BaseEvent {
  event_type = 'view checking account painted door modal';
}

export class ViewClientContractDetail implements BaseEvent {
  event_type = 'view client contract detail';

  constructor(
    public event_properties: ViewClientContractDetailProperties,
  ) {}
}

export class ViewClientDetail implements BaseEvent {
  event_type = 'view client detail';

  constructor(
    public event_properties: ViewClientDetailProperties,
  ) {}
}

export class ViewClientFinancingDetail implements BaseEvent {
  event_type = 'view client financing detail';

  constructor(
    public event_properties: ViewClientFinancingDetailProperties,
  ) {}
}

export class ViewClientInvoiceDetail implements BaseEvent {
  event_type = 'view client invoice detail';

  constructor(
    public event_properties: ViewClientInvoiceDetailProperties,
  ) {}
}

export class ViewClientQuoteDetail implements BaseEvent {
  event_type = 'view client quote detail';

  constructor(
    public event_properties: ViewClientQuoteDetailProperties,
  ) {}
}

export class ViewConnectBankInfoStep implements BaseEvent {
  event_type = 'view connect bank info step';

  constructor(
    public event_properties: ViewConnectBankInfoStepProperties,
  ) {}
}

export class ViewContract implements BaseEvent {
  event_type = 'view contract';

  constructor(
    public event_properties: ViewContractProperties,
  ) {}
}

export class ViewContractLandingPage implements BaseEvent {
  event_type = 'view contract landing page';

  constructor(
    public event_properties?: ViewContractLandingPageProperties,
  ) {}
}

export class ViewContractList implements BaseEvent {
  event_type = 'view contract list';

  constructor(
    public event_properties?: ViewContractListProperties,
  ) {}
}

export class ViewContractSetup implements BaseEvent {
  event_type = 'view contract setup';
}

export class ViewContractsRequestForm implements BaseEvent {
  event_type = 'view contracts request form';

  constructor(
    public event_properties?: ViewContractsRequestFormProperties,
  ) {}
}

export class ViewContractTemplates implements BaseEvent {
  event_type = 'view contract templates';

  constructor(
    public event_properties: ViewContractTemplatesProperties,
  ) {}
}

export class ViewCustomerFeedback implements BaseEvent {
  event_type = 'view customer feedback';

  constructor(
    public event_properties: ViewCustomerFeedbackProperties,
  ) {}
}

export class ViewCustomizeContractTool implements BaseEvent {
  event_type = 'view customize contract tool';
}

export class ViewCustomizeInvoiceTool implements BaseEvent {
  event_type = 'view customize invoice tool';
}

export class ViewDashboard implements BaseEvent {
  event_type = 'view dashboard';
}

export class ViewEmailLeadsSetup implements BaseEvent {
  event_type = 'view email leads setup';
}

export class ViewExistingClient implements BaseEvent {
  event_type = 'view existing client';

  constructor(
    public event_properties: ViewExistingClientProperties,
  ) {}
}

export class ViewFinancingCalculator implements BaseEvent {
  event_type = 'view financing calculator';
}

export class ViewFinancingForm implements BaseEvent {
  event_type = 'view financing form';

  constructor(
    public event_properties: ViewFinancingFormProperties,
  ) {}
}

export class ViewFinancingList implements BaseEvent {
  event_type = 'view financing list';

  constructor(
    public event_properties?: ViewFinancingListProperties,
  ) {}
}

export class ViewFinancingOffers implements BaseEvent {
  event_type = 'view financing offers';

  constructor(
    public event_properties: ViewFinancingOffersProperties,
  ) {}
}

export class ViewFinancingRequestForm implements BaseEvent {
  event_type = 'view financing request form';

  constructor(
    public event_properties?: ViewFinancingRequestFormProperties,
  ) {}
}

export class ViewFinancingUnlockModal implements BaseEvent {
  event_type = 'view financing unlock modal';
}

export class ViewInsights implements BaseEvent {
  event_type = 'view insights';

  constructor(
    public event_properties: ViewInsightsProperties,
  ) {}
}

export class ViewInsightsFirstTimeModal implements BaseEvent {
  event_type = 'view insights first time modal';
}

export class ViewInvoice implements BaseEvent {
  event_type = 'view invoice';

  constructor(
    public event_properties: ViewInvoiceProperties,
  ) {}
}

export class ViewInvoiceList implements BaseEvent {
  event_type = 'view invoice list';

  constructor(
    public event_properties?: ViewInvoiceListProperties,
  ) {}
}

export class ViewInvoiceRequestForm implements BaseEvent {
  event_type = 'view invoice request form';

  constructor(
    public event_properties?: ViewInvoiceRequestFormProperties,
  ) {}
}

export class ViewInvoiceRequestSettings implements BaseEvent {
  event_type = 'view invoice request settings';

  constructor(
    public event_properties: ViewInvoiceRequestSettingsProperties,
  ) {}
}

export class ViewKycFlow implements BaseEvent {
  event_type = 'view kyc flow';

  constructor(
    public event_properties: ViewKycFlowProperties,
  ) {}
}

export class ViewLearnCenter implements BaseEvent {
  event_type = 'view learn center';
}

export class ViewLearnCenterArticle implements BaseEvent {
  event_type = 'view learn center article';

  constructor(
    public event_properties: ViewLearnCenterArticleProperties,
  ) {}
}

export class ViewMarketingTools implements BaseEvent {
  event_type = 'view marketing tools';
}

export class ViewPaymentLandingPage implements BaseEvent {
  event_type = 'view payment landing page';

  constructor(
    public event_properties?: ViewPaymentLandingPageProperties,
  ) {}
}

export class ViewPaymentsApprovalModal implements BaseEvent {
  event_type = 'view payments approval modal';
}

export class ViewPayoutForm implements BaseEvent {
  event_type = 'view payout form';
}

export class ViewProfile implements BaseEvent {
  event_type = 'view profile';
}

export class ViewQuote implements BaseEvent {
  event_type = 'view quote';

  constructor(
    public event_properties: ViewQuoteProperties,
  ) {}
}

export class ViewQuoteRequestForm implements BaseEvent {
  event_type = 'view quote request form';

  constructor(
    public event_properties?: ViewQuoteRequestFormProperties,
  ) {}
}

export class ViewQuoteRevisionForm implements BaseEvent {
  event_type = 'view quote revision form';

  constructor(
    public event_properties: ViewQuoteRevisionFormProperties,
  ) {}
}

export class ViewQuotesEmptyState implements BaseEvent {
  event_type = 'view quotes empty state';
}

export class ViewQuotesFeedback implements BaseEvent {
  event_type = 'view quotes feedback';
}

export class ViewQuotesList implements BaseEvent {
  event_type = 'view quotes list';

  constructor(
    public event_properties?: ViewQuotesListProperties,
  ) {}
}

export class ViewRewardsProgram implements BaseEvent {
  event_type = 'view rewards program';
}

export class ViewSettingSection implements BaseEvent {
  event_type = 'view setting section';

  constructor(
    public event_properties: ViewSettingSectionProperties,
  ) {}
}

export class ViewSuggestedAction implements BaseEvent {
  event_type = 'view suggested action';

  constructor(
    public event_properties: ViewSuggestedActionProperties,
  ) {}
}

export class ViewTeamList implements BaseEvent {
  event_type = 'view team list';
}

export class ViewWebinarScheduler implements BaseEvent {
  event_type = 'view webinar scheduler';
}

export class ViewWelcomeModal implements BaseEvent {
  event_type = 'view welcome modal';
}

export class ViewWistiaChannel implements BaseEvent {
  event_type = 'view wistia channel';

  constructor(
    public event_properties: ViewWistiaChannelProperties,
  ) {}
}

export class WatchSteveWeylVideo implements BaseEvent {
  event_type = 'watch steve weyl video';

  constructor(
    public event_properties: WatchSteveWeylVideoProperties,
  ) {}
}

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: AmplitudeClient;
  private middlewares: Middleware[] = [];

  get client(): AmplitudeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      throw new Error('Ampli is not yet initialized. Have you called ampli.load() on app start?');
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options?: LoadOptions): void {
    this.disabled = options?.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return;
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultConfig, ...options?.client?.config });
    } else {
      throw new Error("ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId  The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  identify(
    userId: string | undefined,
    properties: IdentifyProperties,
    options?: IdentifyOptions,
    extra?: MiddlewareExtra
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: IdentifyEvent = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude?.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude?.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      for (const [key, value] of Object.entries({ ...e.event_properties })) {
        amplitudeIdentify.set(key, value as any);
      }
      this.amplitude?.identify(
        amplitudeIdentify,
        options?.callback,
        // options?.errorCallback
      );
    });
  }

  setGroup(name: string, value: string | string[], options?: GroupOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  track(event: Event, options?: EventOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.runMiddleware({ event, extra }, payload => {
      this.amplitude?.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        // options?.errorCallback,
      );
    });
  }

  /**
   * add additional invoice request
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20additional%20invoice%20request)
   *
   * A contractor adds and schedules an additional invoice request to their one payment request
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. number_of_request)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addAdditionalInvoiceRequest(
    properties: AddAdditionalInvoiceRequestProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddAdditionalInvoiceRequest(properties), options, extra);
  }

  /**
   * add additional provisions to contract request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20additional%20provisions%20to%20contract%20request%20form)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addAdditionalProvisionsToContractRequestForm(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddAdditionalProvisionsToContractRequestForm(), options, extra);
  }

  /**
   * add attachments to contract request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20attachments%20to%20contract%20request%20form)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addAttachmentsToContractRequestForm(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddAttachmentsToContractRequestForm(), options, extra);
  }

  /**
   * add checklist in contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20checklist%20in%20contract%20template)
   *
   * A contractor adds a checklist in the contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addChecklistInContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddChecklistInContractTemplate(), options, extra);
  }

  /**
   * add contract payment detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20contract%20payment%20detail)
   *
   * Owner: Alex Shultz
   *
   * @param properties The event's properties (e.g. number_of_payments)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addContractPaymentDetail(
    properties: AddContractPaymentDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddContractPaymentDetail(properties), options, extra);
  }

  /**
   * add existing client
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20existing%20client)
   *
   * A contractor adds an existing client into the product request form
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. search_used)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addExistingClient(
    properties: AddExistingClientProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddExistingClient(properties), options, extra);
  }

  /**
   * add payment in contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20payment%20in%20contract%20template)
   *
   * A contractor adds a payment in the contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. number_of_payments)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addPaymentInContractTemplate(
    properties: AddPaymentInContractTemplateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddPaymentInContractTemplate(properties), options, extra);
  }

  /**
   * add text entry list in contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/add%20text%20entry%20list%20in%20contract%20template)
   *
   * A contractor adds a text entry list in the contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  addTextEntryListInContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AddTextEntryListInContractTemplate(), options, extra);
  }

  /**
   * answer quick poll
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/answer%20quick%20poll)
   *
   * A contractor responds to a quick poll question on the dashboard; when they click "submit on an answer"
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  answerQuickPoll(
    properties: AnswerQuickPollProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AnswerQuickPoll(properties), options, extra);
  }

  /**
   * approve quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/approve%20quote)
   *
   * A homeowner approves the quote
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  approveQuote(
    properties: ApproveQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ApproveQuote(properties), options, extra);
  }

  /**
   * cancel contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/cancel%20contract)
   *
   * When they click cancel contract
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  cancelContract(
    properties: CancelContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CancelContract(properties), options, extra);
  }

  /**
   * cancel editing contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/cancel%20editing%20contract%20template)
   *
   * A contractor cancels editing a contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  cancelEditingContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CancelEditingContractTemplate(), options, extra);
  }

  /**
   * cancel quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/cancel%20quote)
   *
   * A contractor chooses to cancel a quote
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  cancelQuote(
    properties: CancelQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CancelQuote(properties), options, extra);
  }

  /**
   * change setting
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/change%20setting)
   *
   * A contractor changes setting fields
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. fields)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  changeSetting(
    properties: ChangeSettingProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChangeSetting(properties), options, extra);
  }

  /**
   * claim reward
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/claim%20reward)
   *
   * A contractor claims a reward
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. reward_in_dollar)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  claimReward(
    properties: ClaimRewardProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ClaimReward(properties), options, extra);
  }

  /**
   * complete kyc section
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/complete%20kyc%20section)
   *
   * A contractor completes a kyc section
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  completeKycSection(
    properties: CompleteKycSectionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CompleteKycSection(properties), options, extra);
  }

  /**
   * complete learn center article
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/complete%20learn%20center%20article)
   *
   * A contractor self indicate they have completed a learn center article
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. article_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  completeLearnCenterArticle(
    properties: CompleteLearnCenterArticleProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CompleteLearnCenterArticle(properties), options, extra);
  }

  /**
   * complete onboarding section
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/complete%20onboarding%20section)
   *
   * A contractor completes a section of the onboarding flow
   *
   * Owner: Sondhayni Murmu
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  completeOnboardingSection(
    properties: CompleteOnboardingSectionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CompleteOnboardingSection(properties), options, extra);
  }

  /**
   * complete payout details
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/complete%20payout%20details)
   *
   * A contractor updated their payout details
   *
   * Owner: Stefano Suryanto
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  completePayoutDetails(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CompletePayoutDetails(), options, extra);
  }

  /**
   * confirm kyc personal info
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/confirm%20kyc%20personal%20info)
   *
   * A contractor clicks "confirm" on the "Confirm your information" stage of KYC
   *
   * Owner: Jay Peng
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  confirmKycPersonalInfo(
    properties: ConfirmKycPersonalInfoProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ConfirmKycPersonalInfo(properties), options, extra);
  }

  /**
   * connect bank info
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/connect%20bank%20info)
   *
   * A contractor successfully connects their bank info
   *
   * Owner: Jay Peng
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  connectBankInfo(
    properties: ConnectBankInfoProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ConnectBankInfo(properties), options, extra);
  }

  /**
   * convert quote to contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/convert%20quote%20to%20contract)
   *
   * A contractor chooses to convert an approved quote into a contract
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  convertQuoteToContract(
    properties: ConvertQuoteToContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ConvertQuoteToContract(properties), options, extra);
  }

  /**
   * copy financing link
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/copy%20financing%20link)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  copyFinancingLink(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CopyFinancingLink(), options, extra);
  }

  /**
   * create new contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/create%20new%20contract%20template)
   *
   * A contractor creates new contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createNewContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateNewContractTemplate(), options, extra);
  }

  /**
   * delete contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/delete%20contract%20template)
   *
   * A contractor deletes a contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  deleteContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DeleteContractTemplate(), options, extra);
  }

  /**
   * dismiss checking account painted door modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/dismiss%20checking%20account%20painted%20door%20modal)
   *
   * A contractor dismisses the checking account painted door test modal
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dismissCheckingAccountPaintedDoorModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DismissCheckingAccountPaintedDoorModal(), options, extra);
  }

  /**
   * dismiss checking account painted door suggested action
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/dismiss%20checking%20account%20painted%20door%20suggested%20action)
   *
   * A contractor dismisses the checking account painted door test suggested action
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dismissCheckingAccountPaintedDoorSuggestedAction(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DismissCheckingAccountPaintedDoorSuggestedAction(), options, extra);
  }

  /**
   * dismiss first contract template banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/dismiss%20first%20contract%20template%20banner)
   *
   * A contractor dismisses the first contract template banner
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dismissFirstContractTemplateBanner(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DismissFirstContractTemplateBanner(), options, extra);
  }

  /**
   * dismiss insights first time modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/dismiss%20insights%20first%20time%20modal)
   *
   * A contracor dismisses the Insights launch first-time experience modal
   *
   * Owner: Simon Kim
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dismissInsightsFirstTimeModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DismissInsightsFirstTimeModal(), options, extra);
  }

  /**
   * dismiss suggested action
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/dismiss%20suggested%20action)
   *
   * A contractor dismisses a suggested action on the dashboard
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. suggested_action_cta_copy)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dismissSuggestedAction(
    properties: DismissSuggestedActionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DismissSuggestedAction(properties), options, extra);
  }

  /**
   * download contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/download%20contract)
   *
   * A contractor downloads a filled contract
   *
   * Owner: Stefano Suryanto
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadContract(
    properties: DownloadContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadContract(properties), options, extra);
  }

  /**
   * download contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/download%20contract%20template)
   *
   * A contractor downloads a contract template
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadContractTemplate(), options, extra);
  }

  /**
   * download flyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/download%20flyer)
   *
   * A contractor downloads a flyer
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadFlyer(
    properties: DownloadFlyerProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadFlyer(properties), options, extra);
  }

  /**
   * edit contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20contract%20template)
   *
   * A contractor edits a contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditContractTemplate(), options, extra);
  }

  /**
   * edit contract template name
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20contract%20template%20name)
   *
   * A contractor edits the contract template name
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editContractTemplateName(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditContractTemplateName(), options, extra);
  }

  /**
   * edit contract terms
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20contract%20terms)
   *
   * A contractor changes contract terms
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editContractTerms(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditContractTerms(), options, extra);
  }

  /**
   * edit description in contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20description%20in%20contract%20template)
   *
   * A contractor edits the Description of the Services in the contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editDescriptionInContractTemplate(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditDescriptionInContractTemplate(), options, extra);
  }

  /**
   * edit description of services in contract request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20description%20of%20services%20in%20contract%20request%20form)
   *
   * A contractor enters characters in the description field of contract request form
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editDescriptionOfServicesInContractRequestForm(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditDescriptionOfServicesInContractRequestForm(), options, extra);
  }

  /**
   * edit kyc personal info
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/edit%20kyc%20personal%20info)
   *
   * A contractor clicks "edit" on the "Confirm your information" step of KYC flow
   *
   * Owner: Jay Peng
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  editKycPersonalInfo(
    properties: EditKycPersonalInfoProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EditKycPersonalInfo(properties), options, extra);
  }

  /**
   * finish contracts setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/finish%20contracts%20setup)
   *
   * A contractor finishes contract setup
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  finishContractsSetup(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new FinishContractsSetup(), options, extra);
  }

  /**
   * hover over insight card
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/hover%20over%20insight%20card)
   *
   * A contractor overs over one of the cards on the Insights page/tab
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_card_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  hoverOverInsightCard(
    properties: HoverOverInsightCardProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new HoverOverInsightCard(properties), options, extra);
  }

  /**
   * insights call account manager
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/insights%20call%20account%20manager)
   *
   * A contracor schedules a call with their account manager by clicking on the "Scheudle a meeting with your Account Manager" button at top of the Insights page
   *
   * Owner: Simon Kim
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  insightsCallAccountManager(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new InsightsCallAccountManager(), options, extra);
  }

  /**
   * invite team member
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/invite%20team%20member)
   *
   * A contractor invites a team member
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  inviteTeamMember(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new InviteTeamMember(), options, extra);
  }

  /**
   * join checking account waitlist
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/join%20checking%20account%20waitlist)
   *
   * A contractor joins the checking account waitlist from the painted door test
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  joinCheckingAccountWaitlist(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new JoinCheckingAccountWaitlist(), options, extra);
  }

  /**
   * log in
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/log%20in)
   *
   * A contractor logs in to the mobile app or web app
   *
   * Owner: Stefano Suryanto
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  logIn(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LogIn(), options, extra);
  }

  /**
   * no interest in checking account
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/no%20interest%20in%20checking%20account)
   *
   * A contractor confirms they are not interested in checking account from the painted door test
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  noInterestInCheckingAccount(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NoInterestInCheckingAccount(), options, extra);
  }

  /**
   * open banner design
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/open%20banner%20design)
   *
   * A contractor opens the banner design option in Branding > Marketing Tools "Choose banners that best match your website design and colors"
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  openBannerDesign(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new OpenBannerDesign(), options, extra);
  }

  /**
   * open banner installation help
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/open%20banner%20installation%20help)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  openBannerInstallationHelp(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new OpenBannerInstallationHelp(), options, extra);
  }

  /**
   * open content for your financing page
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/open%20content%20for%20your%20financing%20page)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  openContentForYourFinancingPage(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new OpenContentForYourFinancingPage(), options, extra);
  }

  /**
   * open website payment calculator
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/open%20website%20payment%20calculator)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  openWebsitePaymentCalculator(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new OpenWebsitePaymentCalculator(), options, extra);
  }

  /**
   * pay invoice
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/pay%20invoice)
   *
   * A homeowner pays an invoice
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. connect_invoice_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  payInvoice(
    properties: PayInvoiceProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PayInvoice(properties), options, extra);
  }

  /**
   * post financing link to facebook
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/post%20financing%20link%20to%20facebook)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  postFinancingLinkToFacebook(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PostFinancingLinkToFacebook(), options, extra);
  }

  /**
   * preview contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/preview%20contract)
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  previewContract(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PreviewContract(), options, extra);
  }

  /**
   * preview quote in request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/preview%20quote%20in%20request%20form)
   *
   * A contractor previews a quote before sending
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  previewQuoteInRequestForm(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PreviewQuoteInRequestForm(), options, extra);
  }

  /**
   * preview quote in revision form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/preview%20quote%20in%20revision%20form)
   *
   * A contractor previews a revised quote from the editor view
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  previewQuoteInRevisionForm(
    properties?: PreviewQuoteInRevisionFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PreviewQuoteInRevisionForm(properties), options, extra);
  }

  /**
   * preview sample quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/preview%20sample%20quote)
   *
   * A contractor previews a sample of what the quote would look like
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  previewSampleQuote(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PreviewSampleQuote(), options, extra);
  }

  /**
   * remove contract payment detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/remove%20contract%20payment%20detail)
   *
   * Owner: Alex Shultz
   *
   * @param properties The event's properties (e.g. number_of_payments)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  removeContractPaymentDetail(
    properties: RemoveContractPaymentDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RemoveContractPaymentDetail(properties), options, extra);
  }

  /**
   * remove invoice request
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/remove%20invoice%20request)
   *
   * A contractor removes/deletes an invoice request
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. number_of_request)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  removeInvoiceRequest(
    properties: RemoveInvoiceRequestProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RemoveInvoiceRequest(properties), options, extra);
  }

  /**
   * remove payment in contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/remove%20payment%20in%20contract%20template)
   *
   * A contractor removes a payment in the contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. number_of_payments)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  removePaymentInContractTemplate(
    properties: RemovePaymentInContractTemplateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RemovePaymentInContractTemplate(properties), options, extra);
  }

  /**
   * request changes quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/request%20changes%20quote)
   *
   * A homeowner clicks to request changes to a quote (before submitting the text)
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  requestChangesQuote(
    properties: RequestChangesQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RequestChangesQuote(properties), options, extra);
  }

  /**
   * resend contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/resend%20contract)
   *
   * when they click resent contract
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  resendContract(
    properties: ResendContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ResendContract(properties), options, extra);
  }

  /**
   * resend quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/resend%20quote)
   *
   * A contractor chooses to send a quote again
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  resendQuote(
    properties: ResendQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ResendQuote(properties), options, extra);
  }

  /**
   * save contract template
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/save%20contract%20template)
   *
   * A contractor saves a contract template
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. name_of_template)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  saveContractTemplate(
    properties: SaveContractTemplateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SaveContractTemplate(properties), options, extra);
  }

  /**
   * search plaid institution
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/search%20plaid%20institution)
   *
   * A contractor searches for an institution in Plaid
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchPlaidInstitution(
    properties: SearchPlaidInstitutionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchPlaidInstitution(properties), options, extra);
  }

  /**
   * send banner instructions
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20banner%20instructions)
   *
   * A contractor sends banner instructions email
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendBannerInstructions(
    properties: SendBannerInstructionsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendBannerInstructions(properties), options, extra);
  }

  /**
   * send contracts request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20contracts%20request%20form)
   *
   * A contractor sends a contract
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. button_position)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendContractsRequestForm(
    properties: SendContractsRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendContractsRequestForm(properties), options, extra);
  }

  /**
   * send email leads
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20email%20leads)
   *
   * A contractor sends email leads
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. number_of_emails_uploaded)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendEmailLeads(
    properties: SendEmailLeadsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendEmailLeads(properties), options, extra);
  }

  /**
   * send financing request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20financing%20request%20form)
   *
   * A contractor sends a financing request form
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendFinancingRequestForm(
    properties: SendFinancingRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendFinancingRequestForm(properties), options, extra);
  }

  /**
   * send invoice request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20invoice%20request%20form)
   *
   * A contractor sends an invoice to customer.
   *
   * Owner: Ayana Yaegashi
   *
   * @param properties The event's properties (e.g. connect_invoice_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendInvoiceRequestForm(
    properties: SendInvoiceRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendInvoiceRequestForm(properties), options, extra);
  }

  /**
   * send quote request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20quote%20request%20form)
   *
   * A contractor sends a quote
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. financing_link_included)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendQuoteRequestForm(
    properties: SendQuoteRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendQuoteRequestForm(properties), options, extra);
  }

  /**
   * send revised quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/send%20revised%20quote)
   *
   * A contractor sends a newly revised quote from the editor view
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. financing_link_included)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  sendRevisedQuote(
    properties: SendRevisedQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SendRevisedQuote(properties), options, extra);
  }

  /**
   * set password
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/set%20password)
   *
   * A contractor sets a password during setup flow
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  setPassword(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SetPassword(), options, extra);
  }

  /**
   * share customer feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/share%20customer%20feedback)
   *
   * A contractor shares a customer feedback on social media
   *
   * Owner: Stefano Suryanto
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  shareCustomerFeedback(
    properties: ShareCustomerFeedbackProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ShareCustomerFeedback(properties), options, extra);
  }

  /**
   * sign contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/sign%20contract)
   *
   * A homeowner signs a contract
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  signContract(
    properties: SignContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SignContract(properties), options, extra);
  }

  /**
   * sign terms and conditions
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/sign%20terms%20and%20conditions)
   *
   * A contractor signs terms & conditions during setup flow
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  signTermsAndConditions(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SignTermsAndConditions(), options, extra);
  }

  /**
   * sign terms and conditions for email leads
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/sign%20terms%20and%20conditions%20for%20email%20leads)
   *
   * A contractor signs terms & conditions during email leads setup before sending
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  signTermsAndConditionsForEmailLeads(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SignTermsAndConditionsForEmailLeads(), options, extra);
  }

  /**
   * skip welcome tour
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/skip%20welcome%20tour)
   *
   * A contractor clicks "Skip" on the "Welcome to your dashboard" modal
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  skipWelcomeTour(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SkipWelcomeTour(), options, extra);
  }

  /**
   * start plaid contractor account link
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/start%20plaid%20contractor%20account%20link)
   *
   * A contractor starts linking their bank account with Plaid as part of the final step of KYC
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  startPlaidContractorAccountLink(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new StartPlaidContractorAccountLink(), options, extra);
  }

  /**
   * submit email leads list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20email%20leads%20list)
   *
   * A contractor submits an email leads list through either uploading a csv file or manually entering email addresses
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitEmailLeadsList(
    properties: SubmitEmailLeadsListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitEmailLeadsList(properties), options, extra);
  }

  /**
   * submit feedback form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20feedback%20form)
   *
   * A homeowner submits a feedback
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitFeedbackForm(
    properties: SubmitFeedbackFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitFeedbackForm(properties), options, extra);
  }

  /**
   * submit feedback on learn center article
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20feedback%20on%20learn%20center%20article)
   *
   * A contractor submits feedback and answers the "was this helpful" question
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitFeedbackOnLearnCenterArticle(
    properties: SubmitFeedbackOnLearnCenterArticleProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitFeedbackOnLearnCenterArticle(properties), options, extra);
  }

  /**
   * submit financing form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20financing%20form)
   *
   * A homeowner submits a financing request
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. employment_status)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitFinancingForm(
    properties: SubmitFinancingFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitFinancingForm(properties), options, extra);
  }

  /**
   * submit insight feedback module
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20insight%20feedback%20module)
   *
   * A contractor submits feedback on an Insights page/tab
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_category)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitInsightFeedbackModule(
    properties: SubmitInsightFeedbackModuleProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitInsightFeedbackModule(properties), options, extra);
  }

  /**
   * submit kyc
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20kyc)
   *
   * A contractor submits KYC
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. is_reapplication)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitKyc(
    properties: SubmitKycProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitKyc(properties), options, extra);
  }

  /**
   * submit onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20onboarding)
   *
   * A contractor clicks "Finish" on the "Tell us about your needs" step of Onboarding, and successully submits their set up flow information
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitOnboarding(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitOnboarding(), options, extra);
  }

  /**
   * submit payout
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20payout)
   *
   * A contractor submits a payout request
   *
   * Owner: Stefano Suryanto
   *
   * @param properties The event's properties (e.g. amount_in_cents)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitPayout(
    properties: SubmitPayoutProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitPayout(properties), options, extra);
  }

  /**
   * submit quotes feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20quotes%20feedback)
   *
   * Submitting quotes feedback
   *
   * Owner: Alex Shultz
   *
   * @param properties The event's properties (e.g. feedback)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitQuotesFeedback(
    properties: SubmitQuotesFeedbackProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitQuotesFeedback(properties), options, extra);
  }

  /**
   * submit request for changes quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/submit%20request%20for%20changes%20quote)
   *
   * A homeowner submits the request of changes to a quote
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. quote_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  submitRequestForChangesQuote(
    properties: SubmitRequestForChangesQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SubmitRequestForChangesQuote(properties), options, extra);
  }

  /**
   * take welcome tour
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/take%20welcome%20tour)
   *
   * A contractor clicks "Let's Go" on the "Welcome to your dashboard" modal
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  takeWelcomeTour(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TakeWelcomeTour(), options, extra);
  }

  /**
   * toggle insight card time period
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/toggle%20insight%20card%20time%20period)
   *
   * A contractor toggles the effective time period of a Insight card
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_card_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  toggleInsightCardTimePeriod(
    properties: ToggleInsightCardTimePeriodProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ToggleInsightCardTimePeriod(properties), options, extra);
  }

  /**
   * toggle insight card workflow status
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/toggle%20insight%20card%20workflow%20status)
   *
   * A contractor toggles the status of a Insight card
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_card_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  toggleInsightCardWorkflowStatus(
    properties: ToggleInsightCardWorkflowStatusProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ToggleInsightCardWorkflowStatus(properties), options, extra);
  }

  /**
   * toggle insight card workflow unit
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/toggle%20insight%20card%20workflow%20unit)
   *
   * A contractor toggles the unit of measurement of a Insight card
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_card_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  toggleInsightCardWorkflowUnit(
    properties: ToggleInsightCardWorkflowUnitProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ToggleInsightCardWorkflowUnit(properties), options, extra);
  }

  /**
   * view all client list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20all%20client%20list)
   *
   * A contractor views the index view of all clients.
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewAllClientList(
    properties: ViewAllClientListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewAllClientList(properties), options, extra);
  }

  /**
   * view banner installation website link
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20banner%20installation%20website%20link)
   *
   * Owner: Alex Shultz
   *
   * @param properties The event's properties (e.g. link_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewBannerInstallationWebsiteLink(
    properties: ViewBannerInstallationWebsiteLinkProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewBannerInstallationWebsiteLink(properties), options, extra);
  }

  /**
   * view checking account painted door modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20checking%20account%20painted%20door%20modal)
   *
   * A contractor views checking account painted door test modal
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewCheckingAccountPaintedDoorModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewCheckingAccountPaintedDoorModal(), options, extra);
  }

  /**
   * view client contract detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20client%20contract%20detail)
   *
   * A contractor views a contract detail
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewClientContractDetail(
    properties: ViewClientContractDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewClientContractDetail(properties), options, extra);
  }

  /**
   * view client detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20client%20detail)
   *
   * A contractor views a client detail
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewClientDetail(
    properties: ViewClientDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewClientDetail(properties), options, extra);
  }

  /**
   * view client financing detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20client%20financing%20detail)
   *
   * A contractor views a financing detail
   *
   * Owner: Sondhayni Murmu
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewClientFinancingDetail(
    properties: ViewClientFinancingDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewClientFinancingDetail(properties), options, extra);
  }

  /**
   * view client invoice detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20client%20invoice%20detail)
   *
   * A contractor views an invoice detail
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. connect_invoice_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewClientInvoiceDetail(
    properties: ViewClientInvoiceDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewClientInvoiceDetail(properties), options, extra);
  }

  /**
   * view client quote detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20client%20quote%20detail)
   *
   * A contractor views a quote detail
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. financing_link_included)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewClientQuoteDetail(
    properties: ViewClientQuoteDetailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewClientQuoteDetail(properties), options, extra);
  }

  /**
   * view connect bank info step
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20connect%20bank%20info%20step)
   *
   * A contractor views the CTA to connect their bank info
   *
   * Owner: Jay Peng
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewConnectBankInfoStep(
    properties: ViewConnectBankInfoStepProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewConnectBankInfoStep(properties), options, extra);
  }

  /**
   * view contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contract)
   *
   * A homeowner views a contract
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. contract_service_contract_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContract(
    properties: ViewContractProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContract(properties), options, extra);
  }

  /**
   * view contract landing page
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contract%20landing%20page)
   *
   * A contractor views contract landing page
   *
   * Owner: Stefano Suryanto
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContractLandingPage(
    properties?: ViewContractLandingPageProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContractLandingPage(properties), options, extra);
  }

  /**
   * view contract list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contract%20list)
   *
   * A contractor views workflow contract list
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContractList(
    properties?: ViewContractListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContractList(properties), options, extra);
  }

  /**
   * view contract setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contract%20setup)
   *
   * A contractor views contract setup
   *
   *
   * Trigger only after they clicked on Let's get started
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContractSetup(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContractSetup(), options, extra);
  }

  /**
   * view contracts request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contracts%20request%20form)
   *
   * A contractor views contracts request form
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContractsRequestForm(
    properties?: ViewContractsRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContractsRequestForm(properties), options, extra);
  }

  /**
   * view contract templates
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20contract%20templates)
   *
   * A contractor views contract templates in the contracts editor overview page
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. template_name)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewContractTemplates(
    properties: ViewContractTemplatesProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewContractTemplates(properties), options, extra);
  }

  /**
   * view customer feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20customer%20feedback)
   *
   * A contractor views feedback
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewCustomerFeedback(
    properties: ViewCustomerFeedbackProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewCustomerFeedback(properties), options, extra);
  }

  /**
   * view customize contract tool
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20customize%20contract%20tool)
   *
   * A contractor views customize contract
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewCustomizeContractTool(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewCustomizeContractTool(), options, extra);
  }

  /**
   * view customize invoice tool
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20customize%20invoice%20tool)
   *
   * A contractor views customize invoice tool
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewCustomizeInvoiceTool(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewCustomizeInvoiceTool(), options, extra);
  }

  /**
   * view dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20dashboard)
   *
   * A contractor views dashboard / home
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewDashboard(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewDashboard(), options, extra);
  }

  /**
   * view email leads setup
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20email%20leads%20setup)
   *
   * A contractor views Email Leads setup
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewEmailLeadsSetup(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewEmailLeadsSetup(), options, extra);
  }

  /**
   * view existing client
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20existing%20client)
   *
   * A contractor clicks the "+ Existing client" button in all of the send flows (view financing request form, view invoice request form, view contracts request form, etc.) to view their list of existing clients to send request form to
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewExistingClient(
    properties: ViewExistingClientProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewExistingClient(properties), options, extra);
  }

  /**
   * view financing calculator
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20calculator)
   *
   * A contractor views the financing calculator
   *
   * Owner: Stefano Suryanto
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingCalculator(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingCalculator(), options, extra);
  }

  /**
   * view financing form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20form)
   *
   * A homeowner starts a financing request
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingForm(
    properties: ViewFinancingFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingForm(properties), options, extra);
  }

  /**
   * view financing list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20list)
   *
   * A contractor views workflow financing list
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingList(
    properties?: ViewFinancingListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingList(properties), options, extra);
  }

  /**
   * view financing offers
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20offers)
   *
   * A homeowner view financing offers page
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingOffers(
    properties: ViewFinancingOffersProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingOffers(properties), options, extra);
  }

  /**
   * view financing request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20request%20form)
   *
   * A contractor opens a financing request form
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingRequestForm(
    properties?: ViewFinancingRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingRequestForm(properties), options, extra);
  }

  /**
   * view financing unlock modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20financing%20unlock%20modal)
   *
   * A contractor views the "Financing Unlocked" modal
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewFinancingUnlockModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewFinancingUnlockModal(), options, extra);
  }

  /**
   * view insights
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20insights)
   *
   * The contractor views the Insights page (any tab)
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. insight_category)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInsights(
    properties: ViewInsightsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInsights(properties), options, extra);
  }

  /**
   * view insights first time modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20insights%20first%20time%20modal)
   *
   * A contractor views the Insights launch first-time experience modal
   *
   * Owner: Simon Kim
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInsightsFirstTimeModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInsightsFirstTimeModal(), options, extra);
  }

  /**
   * view invoice
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20invoice)
   *
   * A homeowners views an invoice
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. connect_invoice_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInvoice(
    properties: ViewInvoiceProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInvoice(properties), options, extra);
  }

  /**
   * view invoice list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20invoice%20list)
   *
   * A contractor views workflow invoice list
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInvoiceList(
    properties?: ViewInvoiceListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInvoiceList(properties), options, extra);
  }

  /**
   * view invoice request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20invoice%20request%20form)
   *
   * A contractor views invoicing request form.
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInvoiceRequestForm(
    properties?: ViewInvoiceRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInvoiceRequestForm(properties), options, extra);
  }

  /**
   * view invoice request settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20invoice%20request%20settings)
   *
   * A contractor clicks on the Invoice Request Settings gear button when they are within the invoice workflow
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewInvoiceRequestSettings(
    properties: ViewInvoiceRequestSettingsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewInvoiceRequestSettings(properties), options, extra);
  }

  /**
   * view kyc flow
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20kyc%20flow)
   *
   * A contractor views KYC flow
   *
   * Owner: Stefano Suryanto
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewKycFlow(
    properties: ViewKycFlowProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewKycFlow(properties), options, extra);
  }

  /**
   * view learn center
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20learn%20center)
   *
   * A contractor views learn center
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewLearnCenter(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewLearnCenter(), options, extra);
  }

  /**
   * view learn center article
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20learn%20center%20article)
   *
   * A contractor views a learn center article
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. article_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewLearnCenterArticle(
    properties: ViewLearnCenterArticleProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewLearnCenterArticle(properties), options, extra);
  }

  /**
   * view marketing tools
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20marketing%20tools)
   *
   * A contractor view marketing tools
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewMarketingTools(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewMarketingTools(), options, extra);
  }

  /**
   * view payment landing page
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20payment%20landing%20page)
   *
   * A contractor view payment landing page with terms agreement
   *
   * This is pre KYC
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewPaymentLandingPage(
    properties?: ViewPaymentLandingPageProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewPaymentLandingPage(properties), options, extra);
  }

  /**
   * view payments approval modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20payments%20approval%20modal)
   *
   * A contractor views the "Payments Approved" modal
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewPaymentsApprovalModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewPaymentsApprovalModal(), options, extra);
  }

  /**
   * view payout form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20payout%20form)
   *
   * A contractor opens the initiate payout form
   *
   * Owner: Stefano Suryanto
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewPayoutForm(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewPayoutForm(), options, extra);
  }

  /**
   * view profile
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20profile)
   *
   * A contractor views profile
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewProfile(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewProfile(), options, extra);
  }

  /**
   * view quote
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quote)
   *
   * A homeowner views a quote
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. financing_link_included)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuote(
    properties: ViewQuoteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuote(properties), options, extra);
  }

  /**
   * view quote request form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quote%20request%20form)
   *
   * A contractor opens and views the quote send form
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuoteRequestForm(
    properties?: ViewQuoteRequestFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuoteRequestForm(properties), options, extra);
  }

  /**
   * view quote revision form
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quote%20revision%20form)
   *
   * A contractor views the quote editor to revise a quote that has already been sent
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. homeowner_id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuoteRevisionForm(
    properties: ViewQuoteRevisionFormProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuoteRevisionForm(properties), options, extra);
  }

  /**
   * view quotes empty state
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quotes%20empty%20state)
   *
   * A contractor views the empty state page for quotes
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuotesEmptyState(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuotesEmptyState(), options, extra);
  }

  /**
   * view quotes feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quotes%20feedback)
   *
   * Viewing quotes feedback dialog
   *
   * Owner: Alex Shultz
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuotesFeedback(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuotesFeedback(), options, extra);
  }

  /**
   * view quotes list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20quotes%20list)
   *
   * A contractor views workflow quotes list
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewQuotesList(
    properties?: ViewQuotesListProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewQuotesList(properties), options, extra);
  }

  /**
   * view rewards program
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20rewards%20program)
   *
   * A contractor views rewards program
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewRewardsProgram(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewRewardsProgram(), options, extra);
  }

  /**
   * view setting section
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20setting%20section)
   *
   * A contractor views a setting section
   *
   * Mobile: opens a link under Profile tab
   *
   * Web: opens a tab
   *
   * Owner: Support Shogun
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewSettingSection(
    properties: ViewSettingSectionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewSettingSection(properties), options, extra);
  }

  /**
   * view suggested action
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20suggested%20action)
   *
   * A contractor clicks on to view a suggested action in the dashboard
   *
   * Owner: Ting Ting Liu
   *
   * @param properties The event's properties (e.g. suggested_action_cta_copy)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewSuggestedAction(
    properties: ViewSuggestedActionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewSuggestedAction(properties), options, extra);
  }

  /**
   * view team list
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20team%20list)
   *
   * A contractor views team list
   *
   * Owner: Support Shogun
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewTeamList(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewTeamList(), options, extra);
  }

  /**
   * view webinar scheduler
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20webinar%20scheduler)
   *
   * A contractor clicks on the webinar card on the dashboard to view the webinar scheduler
   *
   * Owner: Ting Ting Liu
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewWebinarScheduler(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewWebinarScheduler(), options, extra);
  }

  /**
   * view welcome modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20welcome%20modal)
   *
   * A contractor views the "Welcome to your dashboard" modal
   *
   * Owner: Jay Peng
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewWelcomeModal(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewWelcomeModal(), options, extra);
  }

  /**
   * view wistia channel
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/view%20wistia%20channel)
   *
   * A user clicks on an element which opens up the wistia channel viewer
   *
   * Owner: Jono Kassan
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewWistiaChannel(
    properties: ViewWistiaChannelProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewWistiaChannel(properties), options, extra);
  }

  /**
   * watch steve weyl video
   *
   * [View in Tracking Plan](https://data.amplitude.com/hearth/Hearth/events/main/70.0.0/watch%20steve%20weyl%20video)
   *
   * A contractor watches a free introductory video to sell better with sales coach Steve Weyl
   *
   * Owner: Simon Kim
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  watchSteveWeylVideo(
    properties: WatchSteveWeylVideoProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new WatchSteveWeylVideo(properties), options, extra);
  }

  addEventMiddleware(middleware: Middleware): void {
    this.middlewares.push(middleware);
  }

  private runMiddleware(payload: MiddlewarePayload, next: MiddlewareNext): void {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext: MiddlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next: MiddlewareNext = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type ConfigExt = Partial<Config> & { plan?: Plan };

export type Plan = {
  branch?: string;
  source?: string;
  version?: string;
}

export enum SpecialEventType {
  Identify = "Identify",
  Group = "Group"
}

export type BaseEvent = {
  event_type: string;
  event_properties?: { [key: string]: any },
  plan?: Plan;
  user_id?: string;
  device_id?: string;
}
export type IdentifyEvent = BaseEvent & { event_type: SpecialEventType.Identify };
export type GroupEvent = BaseEvent & { event_type: SpecialEventType.Group };
export type Event = BaseEvent | IdentifyEvent | GroupEvent;

type BaseEventOptions = Omit<BaseEvent, 'event_type' | 'event_properties'> & {
  callback: Callback;
  errorCallback: Callback;
};
export type EventOptions = BaseEventOptions;
export type IdentifyOptions = BaseEventOptions;
export type GroupOptions = BaseEventOptions;

/**
 * Unstructured object to let users pass extra data to middleware
 */
export interface MiddlewareExtra {
  [name: string]: any;
}

/**
 * Data to be processed by middleware
 */
export interface MiddlewarePayload {
  event: Event;
  extra?: MiddlewareExtra;
}

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 */
export type MiddlewareNext = (payload: MiddlewarePayload) => void;

/**
 * A function to run on the Event stream (each logEvent call)
 *
 * @param payload The event and extra data being sent
 * @param next Function to run the next middleware in the chain, not calling next will end the middleware chain
 */
export type Middleware = (payload: MiddlewarePayload, next: MiddlewareNext) => void;
