import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { gql } from '@apollo/client';

import { useUpdateOrganizationLogoMutation } from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { CheckboxField } from 'common/components/formik';
import { useGql } from 'common/hooks';
import { LogoUpload } from 'contractor/components/widgets';
import { useSnack } from 'common/utils/snackCart';

const useStyles = makeStyles(theme => ({
  logo: {
    color: theme.palette.common.basic900,
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 24,
  },
}));

const PlaceHolder = () => {
  const classes = useStyles();

  return (
    <div className={classes.placeholder}>
      <AddAPhotoIcon className={classes.logo} />
      <Box mt={1} color="common.basic900">
        <Typography variant="body2">
          Drag and drop logo
        </Typography>
      </Box>
    </div>
  );
};

type Props = {
  name: string;
  label: string;
  logoUrl?: string | null;
  refetch: () => void;
}

const LogoUploadField = ({
  name,
  label,
  logoUrl,
  refetch,
}: Props): JSX.Element => {
  const snack = useSnack();
  const { handleMutationError } = useGql();
  const [updateOrganizationLogoMutation] = useUpdateOrganizationLogoMutation();

  const uploadLogoCallback = useCallback(async (file: string) => {
    try {
      const response = await updateOrganizationLogoMutation({
        variables: {
          attributes: {
            logo: file,
          },
        },
      });

      parseGql<PayloadType<typeof response, 'upsertOrganization'>>(
        'upsertOrganization',
        response,
        'UpsertOrganizationSuccess',
        'UpsertOrganizationFailure',
      );

      refetch();
      snack.successSnack('Logo successfully updated');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, updateOrganizationLogoMutation]);

  return (
    <>
      <LogoUpload
        onSubmit={uploadLogoCallback}
        imageAlt="logo"
        Placeholder={PlaceHolder}
        defaultImageSrc={logoUrl}
      />
      <CheckboxField
        name={name}
        label={label}
        disabled={!logoUrl}
      />
    </>
  );
};

LogoUploadField.UPLOAD_LOGO = gql`
  mutation UpdateOrganizationLogo(
    $attributes: OrganizationAttributes!
  ) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          logoUrl
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default LogoUploadField;
