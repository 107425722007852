import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import { useStateList } from 'react-use';
import { useHistory } from 'react-router-dom';
import { ContractBlob } from 'kakemono';
import { isPresent } from 'ts-is-present';

import parseGql, { PayloadType } from 'common/api/parseGql';
import {
  useGetContractWizardDataQuery,
  OrganizationAttributes,
  useUpdateOrganizationCompanyInfoMutation,
  useCreateContractServiceAccountMutation,
  useUpsertContractTemplateVersionMutation,
  useGenerateWizardContractPreviewPdfMutation,
} from 'types';
import { useGql } from 'common/hooks';
import useEditContractTemplateReducer, {
  ContractTemplateActionType,
} from 'contractor/hooks/useEditContractTemplateReducer';

export enum ContractWizardStep {
  WELCOME,
  COMPANY,
  PAYMENT,
  TOS,
}

export const wizardStepDetails = {
  [ContractWizardStep.WELCOME]: {
    title: 'Welcome',
  },
  [ContractWizardStep.COMPANY]: {
    title: 'Company details',
  },
  [ContractWizardStep.PAYMENT]: {
    title: 'Payment details',
  },
  [ContractWizardStep.TOS]: {
    title: 'Terms of use details',
  },
};

/*
 * GQL queries and mutations
 */

export const CONTRACT_WIZARD_FRAGMENTS = gql`
  fragment OrganizationContractWizardInfo on Organization {
    id
    logo
    companyName
    businessLicenseNumber
    address
    state
    city
    zipCode
    logoUrl
    contractServiceAccount {
      id
    }
  }
`;

export const GET_CONTRACT_WIZARD_DATA = gql`
  query GetContractWizardData {
    contractor {
      id
      email
      fullName
      phoneNumber
    }
    organization {
      id
      ...OrganizationContractWizardInfo
    }
    contractServiceBaseTemplate(type:project_contract) {
      id
      template
    }
  }
  ${CONTRACT_WIZARD_FRAGMENTS}
`;

export const UPDATE_COMPANY_INFO = gql`
  mutation UpdateOrganizationCompanyInfo(
    $attributes: OrganizationAttributes!
  ) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          ...OrganizationContractWizardInfo
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${CONTRACT_WIZARD_FRAGMENTS}
`;

export const CREATE_CONTRACT_SERVICE_ACCOUNT = gql`
  mutation CreateContractServiceAccount {
    contractServiceAccountCreate {
      ... on ContractServiceAccountCreateSuccess {
        contractServiceAccount {
          id
        }
      }
      ... on ContractServiceAccountCreateFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const UPSERT_CONTRACT_SERVICE_CONTRACT_TEMPLATE = gql`
  mutation UpsertContractServiceContractTemplate(
    $contractServiceAccountId: ID!,
    $baseTemplateId: String!,
    $template: JSON!
  ) {
    contractServiceContractTemplateUpsert(
      contractServiceAccountId: $contractServiceAccountId
      templateParams: {
        type: project_contract
        template: $template
        baseTemplateId: $baseTemplateId
    }) {
      ... on ContractServiceTemplateUpsertSuccess {
        contractServiceContractTemplate {
          id
          version
          baseTemplate {
            id
          }
        }
      }
      ... on ContractServiceTemplateUpsertFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

// contracts v2
export const UPSERT_FIRST_CONTRACT_TEMPLATE_VERSION = gql`
  mutation UpsertContractTemplateVersion (
    $contractServiceAccountId: ID!,
    $baseTemplateId: String!,
    $template: JSON!,
    $versionName: String!
    $contractorId: ID!,
  ) {
    contractServiceContractTemplateVersionUpsert(
      contractServiceAccountId: $contractServiceAccountId
      templateParams: {
        type: project_contract
        template: $template
        baseTemplateId: $baseTemplateId
        versionName: $versionName
        editedBy: $contractorId
    }) {
      ... on ContractServiceTemplateVersionUpsertSuccess {
        contractServiceContractTemplate {
          id
          version
          baseTemplate {
            id
          }
        }
      }
      ... on ContractServiceTemplateVersionUpsertFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const GENERATE_WIZARD_CONTRACT_PREVIEW_PDF = gql`
  mutation GenerateWizardContractPreviewPdf($html: String!) {
    contractGeneratorRestGeneratePdf(input: { html: $html }) @rest(endpoint: "contractGenerator", method: "POST", path: "/pdf_generator", type: "ContractGeneratorRestGeneratePdfResult") {
      pdf
    }
  }
`;

/*
 * Base hook [DO NOT EXPORT]
 * This hook will be wrapped in constate so that it is accessible anywhere
 */

const useBaseContext = () => {
  const query = useGetContractWizardDataQuery();
  // Used for Step layout portals
  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const [bottomSectionRef, setBottomSectionRef] = useState<HTMLDivElement | null>(null);

  // Maintain JSON
  const [contractTemplate, contractTemplateDispatch] = useEditContractTemplateReducer();

  // Maintain wizard step
  const wizardSteps = [
    ContractWizardStep.WELCOME,
    ContractWizardStep.COMPANY,
    ContractWizardStep.TOS,
  ].filter(isPresent);

  const {
    state: currentStep,
    prev: prevStep,
    next: nextStep,
    currentIndex: stepIndex,
  } = useStateList(wizardSteps);

  // Data from the query
  const contractor = useMemo(() => query.data?.contractor, [query.data]);
  const organization = useMemo(() => query.data?.organization, [query.data]);
  const contractServiceBaseTemplate = useMemo(
    () => query.data?.contractServiceBaseTemplate,
    [query.data],
  );

  // Update template to local state once the data has been fetched
  useEffect(() => {
    const template = contractServiceBaseTemplate?.template;
    if (template) {
      contractTemplateDispatch({
        type: ContractTemplateActionType.SET_TEMPLATE,
        payload: {
          template: template as ContractBlob,
        },
      });
    }
  }, [contractServiceBaseTemplate, contractTemplateDispatch]);

  return {
    contractor,
    organization,
    contractTemplate: {
      baseTemplateId: contractServiceBaseTemplate?.id,
      template: contractTemplate,
      dispatch: contractTemplateDispatch,
    },
    query,
    refs: {
      headerRef,
      setHeaderRef,
      contentRef,
      setContentRef,
      bottomSectionRef,
      setBottomSectionRef,
    },
    wizard: {
      currentStep,
      prevStep,
      nextStep,
      stepIndex,
      steps: wizardSteps,
    },
  };
};

export const [
  ContainerProvider,
  useContractor,
  useOrganization,
  useContractTemplate,
  useQuery,
  useRefs,
  useWizard,
] = constate(
  useBaseContext,
  ({ contractor }) => contractor,
  ({ organization }) => organization,
  ({ contractTemplate }) => contractTemplate,
  ({ query }) => query,
  ({ refs }) => refs,
  ({ wizard }) => wizard,
);

/*
 * Exports
 */

export const ContractWizardProvider = ContainerProvider;

type Actions = {
  updateCompanyInfo: (attributes: OrganizationAttributes) => Promise<void>;
  upsertCurrentTemplate: () => Promise<void>;
  generatePreviewPdf: (html: string) => Promise<string>;
}

export const useContractWizardActions = (): Actions => {
  const { handleMutationError } = useGql();
  const { template, baseTemplateId } = useContractTemplate();
  const organization = useOrganization();
  const contractor = useContractor();
  const history = useHistory();

  /* Actions */
  const [updateOrgCompanyInfoMutation] = useUpdateOrganizationCompanyInfoMutation();
  const [createContractServiceAccountMutation] = useCreateContractServiceAccountMutation();
  const [upsertContractTemplateVersionMutation] = useUpsertContractTemplateVersionMutation();
  const [generateWizardContractPreviewPdfMutation] = useGenerateWizardContractPreviewPdfMutation();

  const updateCompanyInfo = useCallback(async (
    attributes: OrganizationAttributes,
  ) => {
    try {
      const response = await updateOrgCompanyInfoMutation({
        variables: {
          attributes,
        },
      });

      parseGql<PayloadType<typeof response, 'upsertOrganization'>>(
        'upsertOrganization',
        response,
        'UpsertOrganizationSuccess',
        'UpsertOrganizationFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, updateOrgCompanyInfoMutation]);

  const upsertCurrentTemplate = useCallback(async () => {
    try {
      if (!contractor?.id) {
        throw new Error('Contractor not found');
      }

      // typecheck
      if (!baseTemplateId) {
        throw new Error('Base contract template not found');
      }

      // typecheck
      if (!template) {
        throw new Error('Contract template not found');
      }

      let contractServiceAccountId = organization?.contractServiceAccount?.id;

      // Create a service account if none had been made
      if (!contractServiceAccountId) {
        const response = await createContractServiceAccountMutation({
          refetchQueries: ['CheckTermsAcceptance'],
        });

        const result = parseGql<PayloadType<typeof response, 'contractServiceAccountCreate'>>(
          'contractServiceAccountCreate',
          response,
          'ContractServiceAccountCreateSuccess',
          'ContractServiceAccountCreateFailure',
        );

        contractServiceAccountId = result.contractServiceAccount.id;
      }

      const response = await upsertContractTemplateVersionMutation({
        variables: {
          contractServiceAccountId,
          baseTemplateId,
          template,
          contractorId: contractor.id,
          // default name for the very first template made
          versionName: 'Contract Template 1',
        },
        refetchQueries: [
          'GetEditContractTemplate',
          'GetViewContractTemplate',
          'GetAllContractTemplates',
        ],
      });

      parseGql<PayloadType<typeof response, 'contractServiceContractTemplateVersionUpsert'>>(
        'contractServiceContractTemplateVersionUpsert',
        response,
        'ContractServiceTemplateVersionUpsertSuccess',
        'ContractServiceTemplateVersionUpsertFailure',
      );

      history.push('/dashboard/workflows/contracts/contract-templates');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    history,
    handleMutationError,
    template,
    baseTemplateId,
    organization,
    createContractServiceAccountMutation,
    upsertContractTemplateVersionMutation,
    contractor?.id,
  ]);

  const generatePreviewPdf = useCallback(async (html: string) => {
    const { data } = await generateWizardContractPreviewPdfMutation({ variables: { html } });
    if (!data) {
      throw new Error('Data is empty');
    }
    return data.contractGeneratorRestGeneratePdf.pdf;
  }, [generateWizardContractPreviewPdfMutation]);

  return {
    updateCompanyInfo,
    upsertCurrentTemplate,
    generatePreviewPdf,
  };
};
