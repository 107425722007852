import { Box, Collapse } from '@mui/material';
import { useCallback, useState } from 'react';

import { GetStartedSidebar, GetStartedFloatingActionButton } from './components';

const GetStarted = (): JSX.Element => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  return (
    <Box
      sx={{
        position: 'fixed',
        right: 0,
        top: 64,
        height: '100%',
      }}
    >
      <Collapse
        in={sidebarOpen}
        orientation="horizontal"
        timeout={{
          enter: 550,
          exit: 550,
        }}
        sx={{ height: '100%' }}
      >
        <GetStartedSidebar openSidebar={openSidebar} />
      </Collapse>
      <Collapse
        in={!sidebarOpen}
        timeout={{
          enter: 300,
          exit: 200,
        }}
      >
        <GetStartedFloatingActionButton openSidebar={openSidebar} />
      </Collapse>
    </Box>
  );
};

export default GetStarted;
