import { Box, Fab, Typography } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

type Props = {
  openSidebar?: () => void;
};

const GetStartedFloatingActionButton = ({ openSidebar }: Props): JSX.Element => (
  <Box
    sx={{
      bottom: 30,
      right: 40,
      zIndex: 1000,
      position: 'fixed',
    }}
  >
    <Fab
      onClick={openSidebar}
      variant="extended"
      size="large"
      sx={{
        bgcolor: 'common.purple500',
        color: 'common.basic100',
        '&:hover': {
          bgcolor: 'common.purple300',
        },
        '&.MuiButtonBase-root': {
          paddingLeft: '20',
        },
        '&.MuiFab-extended': {
          paddingLeft: '30',
          paddingRight: '30',
        },
      }}
      aria-label="get started"
      data-testid="get-started-fab-btn"
    >
      <LightbulbOutlinedIcon sx={{ color: theme => theme.palette.common.basic100 }} />
      <Typography variant="h4">
        Get Started
      </Typography>
    </Fab>
  </Box>
);

export default GetStartedFloatingActionButton;
