import {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Box, CircularProgress } from '@mui/material';
import { gql } from '@apollo/client';
import { ContractTemplate, ContractBlob } from 'kakemono';

import { useGetViewContractTemplateQuery, useGenerateViewContractPreviewPdfMutation } from 'types';
import { useGql } from 'common/hooks';

import { ContractTemplateBanner } from './components';
import ContractRenderer from './components/ContractRenderer';

const ViewContractTemplate = (): JSX.Element => {
  const { data } = useGetViewContractTemplateQuery();
  const [generateViewContractPreviewPdfMutation] = useGenerateViewContractPreviewPdfMutation();
  const [base64Pdf, setBase64Pdf] = useState<string | null>(null);
  const { handleMutationError } = useGql();

  const previewArgs = useMemo(() => {
    const contractor = data?.contractor;
    const organization = data?.organization;
    if ((contractor != null) && (organization != null)) {
      return {
        contractor: {
          email: contractor.email,
          name: contractor.fullName,
          phoneNumber: contractor.phoneNumber,
        },
        company: {
          name: organization.companyName,
          addressLine1: organization.address,
          addressLine2: `${organization.city}, ${organization.state} ${organization.zipCode}`,
          businessLicense: organization?.businessLicenseNumber ?
            `LIC# ${organization?.businessLicenseNumber}` :
            undefined,
          logo: organization.logoUrl,
        },
      };
    }
    return {};
  }, [data]);

  const contractPreview = useMemo(() => {
    const templateObj = data?.contractServiceContractTemplate?.template;
    if (templateObj) {
      return ContractTemplate(templateObj as ContractBlob).preview(previewArgs);
    }
    return null;
  }, [data?.contractServiceContractTemplate?.template, previewArgs]);

  const generatePreviewPdf = useCallback(async (html: string) => {
    const result = await generateViewContractPreviewPdfMutation({ variables: { html } });
    if (!result.data) {
      throw new Error('Data is empty');
    }
    return result.data.contractGeneratorRestGeneratePdf.pdf;
  }, [generateViewContractPreviewPdfMutation]);

  useEffect(() => {
    if (!contractPreview) return;
    generatePreviewPdf(contractPreview).then(
      setBase64Pdf,
      e => handleMutationError(e, {}),
    );
  }, [contractPreview, generatePreviewPdf, handleMutationError]);

  if (!base64Pdf) {
    return (
      <Box mt={10} display="flex" flexDirection="row" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={910}>
      <ContractTemplateBanner
        base64PDF={base64Pdf}
      />
      <Box mt={3} />
      <ContractRenderer base64PDF={base64Pdf} />
    </Box>
  );
};

ViewContractTemplate.EDIT_CONTRACT_TEMPLATE = gql`
  query GetViewContractTemplate {
    contractor {
      id
      phoneNumber
      fullName
      email
    }
    organization {
      id
      companyName
      fullAddress
      address
      city
      state
      zipCode
      logoUrl
      businessPhone
      businessLicenseNumber
    }
    contractServiceContractTemplate(type:project_contract) {
      id
      template
    }
  }
`;

ViewContractTemplate.GENERATE_VIEW_CONTRACT_PREVIEW_PDF = gql`
  mutation GenerateViewContractPreviewPdf($html: String!) {
    contractGeneratorRestGeneratePdf(input: { html: $html }) @rest(endpoint: "contractGenerator", method: "POST", path: "/pdf_generator", type: "ContractGeneratorRestGeneratePdfResult") {
      pdf
    }
  }
`;

export default ViewContractTemplate;
