import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import { camelizeKeys } from 'humps';
import { useLocation, Redirect } from 'react-router-dom';

import Autologin from 'contractor/modules/Autologin';
import { useVerticalState } from 'common/hooks';

type Search = Partial<{
  token: string;
  email: string;
  noPasswordSet: string;
  redirect: string;
}>;

const AutologinPage = (): JSX.Element => {
  const { search } = useLocation();
  const query = camelizeKeys(parse(search)) as Search;
  const [{ brandMetadata }] = useVerticalState();

  if (!query.token || !query.email) {
    return (
      <Redirect to="/404" />
    );
  }

  return (
    <>
      <Helmet>
        <title>Autologin | {brandMetadata.label}</title>
        <meta
          name="description"
          content="Offer your customers hassle-free, zero-fee financing that
                   works for your customers – and grows your business."
        />
      </Helmet>
      <Autologin
        token={query.token}
        email={query.email}
        noPasswordSet={query.noPasswordSet ? query.noPasswordSet === 'true' : false}
        redirectPath={query.redirect}
      />
    </>
  );
};

export default AutologinPage;
