import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';

type Props = {
  openSidebar: () => void;
};

const SidebarContainer = styled(Box, {
  name: 'SidebarContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.purple100,
  width: '400px',
  height: '100%',
}));

const SidebarHeader = styled(Box, {
  name: 'SidebarHeader',
})({
  width: '100%',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  columnGap: '20%',
  marginTop: 4,
  marginRight: 36,
});

const GetStartedSidebar = ({ openSidebar }: Props): JSX.Element => (
  <SidebarContainer data-testid="get-started-sidebar">
    <SidebarHeader>
      <Typography variant="h3">
        Get Started
      </Typography>
      <IconButton
        onClick={openSidebar}
        aria-label="collapse sidebar icon"
      >
        <CloseFullscreenRoundedIcon />
      </IconButton>
    </SidebarHeader>
  </SidebarContainer>
);

export default GetStartedSidebar;
